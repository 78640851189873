import React from "react";
import moment from "moment";

const Copyright: React.FC = () => {
  return (
    <div className="copyright">
      <div className="container">
        &copy; {moment().format("Y")}{" "}
        <a href="https://everythingcivic.com/" target="_blank" rel="noreferrer">
        </a>{" "}
        Powered by{" "}
        <a
          href="https://www.siliconithub.com/"
          target="_blank"
          rel="noreferrer"
        >
          Civic Solutions Pvt. Ltd.
        </a>
      </div>
    </div>
  );
};

export default Copyright;