import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
// import AuthSliceReducer from "./AuthSlice";
import CommonSliceReducer from "./CommonSlice";
import ContactSliceReducer from "../views/modules/Contact/utils/ContactSlice";
import BlogSlice from "../views/modules/Blog/Utils/BlogSlice";
import CareerSlice from "../views/modules/Career/Utils/Career";

const store = configureStore({
  reducer: {
    // AUTH: AuthSliceReducer,

    COMMON: CommonSliceReducer,
    CONTACT: ContactSliceReducer,
    BLOG: BlogSlice,
    CAREER:CareerSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<any>
>;

export default store; // Export the store as the default export
