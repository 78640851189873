import Message from "../../../config/Message";
import { validations } from "../../../config/validations/validations";

export const rules: any = {
    first_name: () => [
        validations.required.text(),
        validations.max.text(50)
    ],
    last_name: () => [
        validations.required.text(),
        validations.max.text(50)
    ],
    email: () => [
        validations.pattern.email,
        validations.max.text(100),
        { required: true, message: Message().required.text() },
    ],
    phone: () => [
        validations.required.text(),
        validations.pattern.phone,
        validations.pattern.numeric,
    ],
    notice: () => [
        validations.required.text(),
        validations.max.text(100),
        // () => ({
        //     validator(_: any, value: any) {
        //         if (value > 100) {
        //             return Promise.reject(validations.max.number(100));
        //         } else {
        //             return Promise.resolve();
        //         }
        //     }
        // })
    ],
    reason: () => [
        validations.required.text(),
        validations.max.text(100),
    ],
    message: () => [
        validations.required.text(),
        validations.max.text(100),
    ],
    location: () => [
        validations.required.text(),
        validations.max.text(255),
    ],
    resume: () => [
        validations.required.select(),
    ]

}