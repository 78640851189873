import React from "react";
import { Col, Row } from "antd";
import Slider from "react-slick";

import AreaBg from "../../assets/images/area.png";
import SmartPhone from "../../assets/images/smart-pp.png";
import FixIcon from "../../assets/images/fixIcon.png";

import TestimonialTwoJson from "./TestimonialTwo.json";

import "../../assets/styles/commonSections/testimonialTwo.less";

const TestimonialTwo: React.FC = () => {
  const settings = {
    dots: true,
    arrows: false,
    autoplay: true,
    centerMode: true,
    centerPadding: "00px",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
  };

  const testimonialItem = (
    title: string,
    description: string,
    index: number
  ) => (
    <div className="testimonialItem" key={index}>
      <strong className="title">{title}</strong>
      <p>{description}</p>
    </div>
  );

  return (
    <section id="TestimonialTwo">
      <div
        className="sliderContainer"
        style={{ backgroundImage: `url(${AreaBg})` }}
      >
        <Row gutter={30} align={"bottom"}>
          <Col xs={24} lg={12} className="text-right">
            <div className="sliderTestimonial">
              <img src={FixIcon} alt=" " className="fixIcon" />
              <Slider {...settings}>
                {TestimonialTwoJson &&
                  TestimonialTwoJson.map((item: any, index: number) => {
                    return testimonialItem(item.title, item.description, index);
                  })}
              </Slider>
            </div>
          </Col>
        </Row>
        <img src={SmartPhone} alt="" className="SmartPhone" />
      </div>
    </section>
  );
};
export default TestimonialTwo;
