import React from "react";
import { ReactComponent as EmailIcon } from "../assets/images/icons/email.svg";
import { ReactComponent as CallIcon } from "../assets/images/icons/call.svg";
import { ReactComponent as RightTopArrowIcon } from "../assets/images/icons/rightTopArrow.svg";
import { ReactComponent as TickInCircleIcon } from "../assets/images/icons/tickInCircle.svg";

import { ReactComponent as FacebookIcon } from "../assets/images/icons/facebook.svg";
import { ReactComponent as TwitterIcon } from "../assets/images/icons/twitter.svg";
import { ReactComponent as LinkedinIcon } from "../assets/images/icons/linkedin.svg";
import { ReactComponent as GlobeIcon } from "../assets/images/icons/globe.svg";
import { ReactComponent as SearchIcon } from "../assets/images/icons/search.svg";
import { ReactComponent as MapIcon } from "../assets/images/icons/map.svg";
import { ReactComponent as LocationIcon } from "../assets/images/icons/location.svg";
import { ReactComponent as MonitorSearchIcon } from "../assets/images/icons/monitorSearch.svg";
import { ReactComponent as AddressIcon } from "../assets/images/icons/address.svg";
import { ReactComponent as QouteIcon } from "../assets/images/icons/qoute.svg";
import { ReactComponent as StateIcon } from "../assets/images/icons/state.svg";
import { ReactComponent as HandIcon } from "../assets/images/icons/hand.svg";
import { ReactComponent as OfficeIcon } from "../assets/images/icons/office.svg";
import { ReactComponent as ResetIcon } from "../assets/images/icons/reset.svg";
import { ReactComponent as Help } from "../assets/images/icons/helpsupport.svg";

interface IconProps {
  icon: string; //make the clear type to make switch
  className?: string;
  color?: string;
  width?: number;
}

const SVGIcon = ({
  icon,
  color = "#696b71",
  width = 14,
  className,
}: IconProps) => {
  // In this case you have to think about the switch and types in typescript.
  const Icons: any = {
    email: (
      <EmailIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    phone: (
      <CallIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    rightTopArrow: (
      <RightTopArrowIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    tickInCircle: (
      <TickInCircleIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    facebook: (
      <FacebookIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    twitter: (
      <TwitterIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    linkedin: (
      <LinkedinIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    globe: (
      <GlobeIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    search: (
      <SearchIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    map: (
      <MapIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    location: (
      <LocationIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    monitorSearch: (
      <MonitorSearchIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    address: (
      <AddressIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    qoute: (
      <QouteIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    state: (
      <StateIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    hand: (
      <HandIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    office: (
      <OfficeIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    reset: (
      <ResetIcon
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
    Help:(
      <Help
        fill={color}
        width={width}
        height={width}
        className={className}
      />
    ),
  };
  return Icons[icon];
};

export default SVGIcon;
