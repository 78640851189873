import React from "react";
import { Col, Row } from "antd";
import FormComponents from "./components/FormComponents";

import "../../../assets/styles/modules/contact.less";
import ContactJson from "./contact.json";
import ContactDetails from "./components/ContactDetails";
// import { AnimationOnScroll } from "react-animation-on-scroll";
// import Drone from "../../../assets/images/drone.png";
// import InstantSupport from "./components/InstantSupport";
import Banner from "./components/Banner";

const Contact: React.FC = () => {
  return (
    <section id="contact">
      <Banner />
      {/* <AnimationOnScroll animateIn="animate__fadeInUpBig" offset={200}>
        <img src={Drone} alt="" className="drone" />
      </AnimationOnScroll> */}
      <div className="container">
        <Row gutter={[30, 60]} align={"top"}>
          <Col xs={24} lg={12}>
            <FormComponents />
          </Col>
          <Col xs={24} lg={10}>
            <ContactDetails contactData={ContactJson?.contact} />
          </Col>
          {/* <Col xs={24}>
            <InstantSupport supportData={ContactJson?.support} />
          </Col> */}
        </Row>
      </div>
    </section>
  );
};

export default Contact;
