import CertificateSection from "./CertificateSection";
import SVGIcon from "../../../../../utils/SVGIcon";
import { Link } from "react-router-dom";

interface MyComponent {
  aboutData: any;
}
const ContentSection: React.FC<MyComponent> = ({ aboutData }) => {
  const { title, tag, content, sub_title } = aboutData;
  return (
    <div className="contentSection">
      <strong className="titleTag leftLine primaryColor">
        {tag ?? "About Us"}
      </strong>
      <h2 className="titleSection">{title ?? "Civic Solutions"}</h2>
      <p>
        {content ??
          "As a subsidiary of Silicon IT Hub Private Limited, one of the fastest-growing web and mobile app development companies, Civic Solutions is dedicated to designing and developing high-end solutions for government agencies. Our top-quality web and mobile-based solutions can streamline the workflows of authorities and city civic centers and enable them to serve civilians in a better way."}
      </p>
      <h3 className="subTitleSection">
        <SVGIcon icon="tickInCircle" />
        {sub_title ?? "Achievements & Certifications"}
      </h3>
      <CertificateSection />
      <Link
        to={"/about"}
        rel="noreferrer"
        className="buttonLink primaryColor"
        title="Read More"
      >
        Read More <SVGIcon icon="rightTopArrow" />
      </Link>
    </div>
  );
};
export default ContentSection;
