import React from "react";
import SVGIcon from "../../../../utils/SVGIcon";
import { NavLink } from "react-router-dom";
interface MyComponent {
  actionLink: any;
  socialLink: any;
  help_support: any
}
const ActionStrip: React.FC<MyComponent> = ({ actionLink, socialLink, help_support }) => {
  return (
    <div className="actionStrip">
      <div className="contact">
        {actionLink?.map((item: any, index: number) => {
          return item.name === "email" ? (
            <a
              key={index}
              href={`mailto:${item.link}`}
              target="_blank"
              rel="noreferrer"
              title={item.link}
            >
              <SVGIcon icon={item.name} />
              <span>{item.link}</span>
            </a>
          )
            :
            item.name === "phone" ? (
              <a
                key={index}
                href={`tel:${item.link}`}
                target="_blank"
                rel="noreferrer"
                title={item.link}
              >
                <SVGIcon icon={item.name} />
                <span>Technical Inquiry : {item.link}</span>
              </a>
            )
              : null;
        })}
      </div>
      <div className="social">
        <div className="helpAndSupport">

          <NavLink to={help_support.link} title={help_support.name}>
            <SVGIcon icon={help_support.name} className="helpIcon" />
            <span> Help and support<SVGIcon icon="rightTopArrow" /></span>
          </NavLink>


        </div>
        <ul>
          {socialLink?.map((item: any, index: number) => {
            return (
              <li key={index}>
                <a href={item.link} target="_blank" rel="noreferrer" title={item.name}>
                  <SVGIcon
                    icon={item.name}
                    className={item.name === "twitter" ? "stroke" : ""}
                  />
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default ActionStrip;
