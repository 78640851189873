import React from "react";
import CityImg from "../../assets/images/city_bg1.png";
import LineImg from "../../assets/images/line1.png";
import Step1 from "../../assets/images/step1.png";
import Step2 from "../../assets/images/step2.png";
import Step3 from "../../assets/images/step3.png";
import "../../assets/styles/commonSections/solution.less";
import SVGIcon from "../../utils/SVGIcon";
import { Link } from "react-router-dom";

const Solution: React.FC = () => {
  const step = (
    className: string,
    stepNo: string,
    title: string,
    imageURL: string
  ) => (
    <div className={`step ${className}`}>
      <img src={imageURL} alt="" />
      <strong className="stepNo">{stepNo}</strong>
      <h3 className="title">{title}</h3>
    </div>
  );

  return (
    <section id="Solution">
      <strong className="titleTag leftRightLine primaryColor">
      EFFECTIVE SMART CITY SOLUTION
      </strong>
      <h2 className="titleSection">3-Step Citizen Complaints Resolution System</h2>

      <div className="steps">
        {step("Step1", "1", "Spot", Step1)}
        {step("Step2", "2", "Snap", Step2)}
        {step("Step3", "3", "Solve", Step3)}
        <img src={LineImg} alt="" className="LineImg" />
      </div>
      <div className="text-center">
        <Link
          to={'/contact-us'}
          rel="noreferrer"
          className="buttonLink primaryColor"
        >
          Get Started <SVGIcon icon="rightTopArrow" />
        </Link>
      </div>
      <img src={CityImg} alt="" className="CityImg" />
    </section>
  );
};
export default Solution;
