import React from 'react'
import coreData from "./OurCoreFeatures.json"
import "../../assets/styles/commonSections/coreFeatures.less"

import {  Col, Row } from 'antd';
import { ReactComponent as CardImages1 } from "../../assets/images/icons/Complain.svg";
import { ReactComponent as CardImages2 } from "../../assets/images/icons/NearMe.svg";
import { ReactComponent as CardImages3 } from "../../assets/images/icons/Property.svg";
import { ReactComponent as CardImages4 } from "../../assets/images/icons/Certificate.svg";
import { ReactComponent as CardImages5 } from "../../assets/images/icons/Helpline.svg";
import { ReactComponent as CardImages6 } from "../../assets/images/icons/Appointment.svg";
import { ReactComponent as CardImages7 } from "../../assets/images/icons/traffic.svg";
import { ReactComponent as CardImages8 } from "../../assets/images/icons/about.svg";
import SVGIcon from '../../utils/SVGIcon';
import Slider from "react-slick";
import { Link } from 'react-router-dom';

const OurCoreFeatures = () => {
    const cardImages = [
        <CardImages1 />,
        <CardImages2 />,
        <CardImages3 />,
        <CardImages4 />,
        <CardImages5 />,
        <CardImages6 />,
        <CardImages7 />,
        <CardImages8 />
    ]
    const settings = {
        arrows: false,
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerPadding: "10px",
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <section id="coreFeatures">
            <div className="container">
                <Row gutter={30} align={'middle'} justify={'space-between'}>
                    <Col md={24} lg={8} xl={8}>
                        <strong className="titleTag leftLine primaryColor">
                            {coreData.subTitle}
                        </strong>
                        <h2 className="title">
                            {coreData.title}
                        </h2>
                        <p className='leftContent'>{coreData.content}</p>
                        {/* <Button
                            className=" buttonLink buttonPrimary"
                            onClick={() => navigate("/features")}
                        >
                            View More
                            <SVGIcon icon="rightTopArrow" />
                        </Button> */}
                        <Link
                            to={"/features"}
                            title="View More"
                            className="buttonLink primaryColor"
                        >
                            View More <SVGIcon icon="rightTopArrow" />
                        </Link>
                    </Col>
                    <Col md={24} lg={16} xl={16}>
                        <div className='card'>
                            <Slider {...settings}>
                                {coreData?.cards.length > 0 && coreData.cards.map((item: any, index: number) => {
                                    return (

                                        <div key={item.id} className='cardInnerContent '>
                                            <div className='cardContent'>
                                                <div className='cardIcon'>
                                                    {cardImages[index]}
                                                </div>
                                                <h3>{item.title}</h3>
                                                <p>{item.description}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Slider>

                        </div>

                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default OurCoreFeatures