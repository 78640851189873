import "../../../assets/styles/modules/career.less"
import { ReactComponent as ApplyIcon } from "../../../assets/images/icons/applyIcon.svg"
import { Button, Col, Divider, Modal, Form, Row, Upload, message } from "antd"
import SVGIcon from "../../../utils/SVGIcon"
import { useEffect, useState } from "react"
import { InputBox } from "../../../components/AntdAddons"
import { ReactComponent as UploadImage } from "../../../assets/images/uploadImage.svg"
import InnerBanner from "../../../components/Sections/InnerBanner"
import { AppDispatch } from "../../../store/app"
import { useDispatch } from "react-redux"
import { careerApply, getCareerData } from "./Utils/Career"
import PageSpinner from "../../../components/PageSpinner/PageSpinner"
import { rules } from "./Validation"
import { assignErrorToInput } from "../../../store/api"
import RequirementSection from "../../../components/Sections/RequirementSection"



const Career: React.FC = () => {
    const [form] = Form.useForm();
    const [openModal, setOpenModal] = useState(false)
    const [fileList, setFileList] = useState<any>([])
    const dispatch: AppDispatch = useDispatch()
    const [loading, setLoading] = useState(true);
    const [careerData, setCareerData] = useState<any>([]);
    const [listId, setListId] = useState<any>();
    const [btnLoading, setBtnLoading] = useState<boolean>()
    const [detailsModal, setDetailsModal] = useState<boolean>(false)
    const [detailsData, setDetailsData] = useState<any>()

    const openDetailsModal = (data: any) => {
        setDetailsModal(true)
        setDetailsData(data)
    }
    const closeDetailsModal = () => {
        setDetailsModal(false)
    }
    const openForm = (data: any) => {
        setListId(data) //this state for pass id in apply form

        setOpenModal(true)
    }
    const handleCancel = () => {
        setOpenModal(false)
        form.resetFields()
    }
    const checkFileType = (file: any) => {
        const isLt5M = file.size / 1024 / 1024 < 5;


        const acceptedFormats = [
            "application/x-ms-dos-executable",
            "application/zip"

        ];

        if (acceptedFormats.includes(file.type)) {
            message.error(`The file format you provided is invalid!`);
            return true;
        }
        if (!isLt5M) {
            message.error('It must smaller than 5MB!');
            return true;
        } else {
            return false;
        }
    };
    const onChange = (data: any) => {
        handleChange()
        if (data.file?.status === "error" || data.file?.status === "uploading") {
            setFileList([]);
        } else {
            setFileList(data.fileList);
        }
    };
    useEffect(() => {
        dispatch(getCareerData()).then((data: any) => {
            setCareerData(data?.career)
            setLoading(false)
        })
            .catch((e: any) => e)
            .finally(() => {
                setLoading(false)
            })
    }, [dispatch])

    const handleChange = () => {
        form.validateFields()
    }

    const handleSubmit = (data: any) => {
        const formData = new FormData()

        for (const keys in data) {
            formData.append(keys, data[keys] ?? null);
        }
        if (fileList) {
            formData.append("resume", fileList[0].originFileObj)
        }
        formData.delete("biodata")
        setBtnLoading(true)
        dispatch(careerApply(listId, formData))
            .then(() => {
                setOpenModal(false)
                form.resetFields()
                setFileList([]);
                setBtnLoading(false)
            })
            .catch((e: any) => {
                setBtnLoading(false)
                assignErrorToInput(form, e?.NOTIFICATION)
            })
            .finally(() => {
                setBtnLoading(false)
            })
    }



    return !loading ? (
        <section id='career'>
            <InnerBanner title="Career" subTitle="Current Openings" tag="We are Hiring For" />
            <div className="container">
                <Row gutter={[30, 90]}>
                    {careerData.length > 0
                        ? careerData.map((item: any) => {
                            return (
                                <Col xs={24}  >
                                    <div className="userIcon">
                                        {item?.icon_url ? <img src={item.icon_url} alt="userIcon" /> :
                                            <ApplyIcon />
                                        }
                                    </div>
                                    <div className="applyList">

                                        <div className="applyListContent">

                                            <div className="qualification">
                                                <h4 className="title">{item.title}</h4>
                                                <span className="qualificationtTitle">Qualification</span><b>{item.qualification}</b>
                                            </div>
                                            <div className="experienceContent">
                                                <div className="experience">
                                                    <span className="qualificationtTitle">Eexperience</span><b>{item.experience}</b>
                                                </div>
                                                <Divider type="vertical" />
                                                <div className="positions">
                                                    <span className="qualificationtTitle">Positions</span><b>{item.position}</b>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="applyListBtn">
                                            <Button className="buttonLink primaryColor outline" title="View Details" onClick={() => openDetailsModal(item.description)}>
                                                View Details
                                            </Button>
                                            <Button className="buttonLink primaryColor " title="Apply Now" onClick={() => openForm(item.id)}>
                                                {item.button ? item.button : "Apply Now"} <SVGIcon icon="rightTopArrow" />
                                            </Button>

                                        </div>
                                    </div>

                                </Col>

                            )
                        })
                        : <Col xs={24} className="text-center">
                            <h2>No Current Job Openings</h2>
                            <p>We appreciate your interest in working with us. Unfortunately, there are no job openings available at this time. Please check back later for any updates.</p>
                        </Col>
                    }
                </Row>

                <Row className="requirement" justify={"center"} gutter={90}>
                    <RequirementSection
                        title="Let’s Grow Together"
                        content="<p>Just take a step forward, and we can make history</p>"
                        subTitle="Send Your Requirement"
                    />

                </Row>
            </div>
            <Modal
                width={750}
                open={openModal}
                className="careerForm"
                onCancel={handleCancel}
                title="Let's begin with your Profile"
                footer={
                    <p>For Queries Call <a href="tel:+91 (79) 66634343"> +91 (79) 66634343 </a> OR Email at <a href="mailto:hr@everythingcivic.com">hr@everythingcivic.com</a></p>
                }
            >
                <Form
                    name="basic"
                    form={form}
                    labelCol={{ span: 24 }}
                    autoComplete="off"
                    onChange={handleChange}
                    onFinish={handleSubmit}
                >
                    <Row gutter={30}>
                        <Col xs={24} md={12}  >
                            <InputBox.Text
                                name="first_name"
                                type='text'
                                label="First Name"
                                rules={rules.first_name()}
                                required
                            />
                        </Col>
                        <Col xs={24} md={12}  >
                            <InputBox.Text
                                name="last_name"
                                type='text'
                                label="Last Name"
                                rules={rules.last_name()}
                                required
                            />
                        </Col>

                    </Row>
                    <Row gutter={30}>
                        <Col xs={24} md={12}  >
                            <InputBox.Text
                                name="email"
                                type='text'
                                label="Email"
                                rules={rules.email()}
                                required
                            />
                        </Col>
                        <Col xs={24} md={12}  >
                            <InputBox.Text
                                name="mobile_number"
                                type='text'
                                maxLength={10}
                                label="Phone No."
                                rules={rules.phone()}
                                required
                            />
                        </Col>

                    </Row>
                    <Row gutter={30}>
                        <Col xs={24} md={12}  >
                            <InputBox.Text
                                name="notice_period"
                                label="Notice Period"
                                required
                                placeholder="Notice Period"
                                rules={rules.notice()}
                            />
                        </Col>
                    </Row>
                    <Row gutter={30}>
                        <Col xs={24}  >
                            <InputBox.TextArea
                                name="reason_for_change"
                                label="Reason for Change"
                                placeholder="Reason for Change"
                                rows={1}
                                rules={rules.reason()}
                                required
                            />
                        </Col>
                    </Row>
                    <Row gutter={30}>
                        <Col xs={24}  >
                            <InputBox.TextArea
                                name="message"
                                label="Message"
                                placeholder="Message"
                                rules={rules.message()}
                                rows={4}
                                required
                            />
                        </Col>
                    </Row>
                    <Row gutter={30}>
                        <Col xs={24}  >
                            <InputBox.TextArea
                                name="location"
                                label="Location"
                                placeholder="Location"
                                rules={rules.location()}
                                rows={1}
                                required
                            />
                        </Col>
                    </Row>
                    <Row gutter={30}>

                        <Col span={24}  >
                            <Form.Item name="biodata"
                                required
                                label="Upload CV"
                                rules={fileList.length < 1 ? rules.resume() : []}
                            >
                                <Upload
                                    className="cvUpload"
                                    fileList={fileList}
                                    onChange={onChange}
                                    beforeUpload={checkFileType}
                                    accept='.pdf, .jpeg, .png. .jpg '
                                >

                                    {fileList.length < 1 &&
                                        'Browse'
                                    }

                                </Upload>
                                <div className="uploadImage">
                                    <UploadImage />
                                </div>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row gutter={30}>
                        <Col xs={24} className="submitForm" >
                            <Button className="buttonLink primaryColor outline" htmlType="submit" loading={btnLoading}>
                                Appy Now <SVGIcon icon="rightTopArrow" />
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal
                onCancel={closeDetailsModal}
                open={detailsModal}
                className="careerForm careerDetailsModal"
                width={650}
                footer={
                    <Button className="buttonLink primaryColor outline" onClick={() => closeDetailsModal()}>
                        Close
                    </Button>
                }

            >
                <div dangerouslySetInnerHTML={{ __html: detailsData }}></div>
            </Modal>
        </section>
    ) : <PageSpinner />
}

export default Career