import { RequestProps } from "../../../config/InterfacesAndTypes";
import Message from "../../../config/Message";

export const ContactRequest = (): RequestProps => ({
  full_name: (field?: string) => [
    { required: true, message: Message().required.text(field) },
  ],
  category: (field?: string) => [
    { required: true, message: Message().required.select(field) },
  ],
  email: (field?: string) => [
    { required: true, message: Message().required.text(field) },
    { type: "email", message: Message().email(field) },
  ],
  subject: (field?: string) => [
    { required: true, message: Message().required.text(field) },
  ],
  message: (field?: string) => [
    { required: true, message: Message().required.text(field) },
  ],
  re_captcha: (field?: string) => [
    { required: true, message: Message().required.text(field) },
    ({ getFieldValue }) => ({
      validator() {
        const captcha = getFieldValue("captcha");
        const recaptcha = getFieldValue("re_captcha");

        if (recaptcha) {
          if (captcha.trim() === recaptcha) {
            return Promise.resolve();
          } else {
            return Promise.reject("CAPTCHA does not match.");
          }
        }
        return Promise.resolve();
      },
    }),
  ],
});
