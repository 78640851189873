import React from 'react'
import { ReactComponent as Facebook } from "../../../../assets/images/icons/facebook.svg";
import { ReactComponent as Twiter } from "../../../../assets/images/icons/twitter.svg";
import { ReactComponent as Linkdin } from "../../../../assets/images/icons/linkedin.svg";
import moment from 'moment';
import { Divider, Form, Input } from 'antd';
import Email from "../../../../assets/images/emailsend.png"
import { rules } from '../Utils/Rules';
import { Link } from 'react-router-dom';


const BlogDetailsRight = (props: any) => {

    const { latest_blogs, tags } = props

    const firstThree = latest_blogs?.slice(0, 3);
    const [form] = Form.useForm();
    const handleChange = () => {
        form.validateFields()
    }

    return (
        <div className='blogDetailRight'>
            <h2 className='rightTitle'>Recent Blogs</h2>
            <div className='recentBlog'>
                {firstThree.length > 0 &&
                    firstThree.map((item: any) => {
                        return (
                            <div className='recentBlogContent' key={item.id}>
                                <div className='recentBlogImg'>
                                    {item?.image_url && <img src={item.image_url} alt={item?.title} />}                                  
                                </div>
                                <div className='recentBlogContant'>
                                    {item?.title && <Link to={`/blog/${item.slug}`} title={item?.title} className='contantTitle' >{item?.title}</Link>}
                                    <div className='recentBlogDate'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                                            <path d="M10.6996 2.19995H2.29961C1.63687 2.19995 1.09961 2.73721 1.09961 3.39995V11.8C1.09961 12.4627 1.63687 13 2.29961 13H10.6996C11.3624 13 11.8996 12.4627 11.8996 11.8V3.39995C11.8996 2.73721 11.3624 2.19995 10.6996 2.19995Z" stroke="#2490EB" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M8.89941 1V3.4" stroke="#2490EB" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M4.09961 1V3.4" stroke="#2490EB" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M1.09961 5.80005H11.8996" stroke="#2490EB" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        {item?.publish_date && <span className='date'>{moment(item?.publish_date).format("MMM, DD YYYY")}</span>}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

            </div>

            <div className='articleSection'>
                <h2 className='rightTitle'>Share this Article</h2>
                <div className='social'>
                    <a href='https://www.facebook.com/everythingcivic' rel="noreferrer" title="https://www.facebook.com/everythingcivic" target='_blank' className='socialIcon'>
                        <Facebook />
                    </a>
                    <a href='https://www.linkedin.com/company/everythingcivic' rel="noreferrer" title='https://www.linkedin.com/company/everythingcivic' target='_blank' className='socialIcon'>
                        <Linkdin />
                    </a>
                    <a href='https://twitter.com/everythingcivic' rel="noreferrer" title='https://twitter.com/everythingcivic' target='_blank' className='socialIcon'>
                        <Twiter className='stroke' />
                    </a>
                </div>
            </div>

            {/* <Divider /> */}
            <div className='subscription email'>
                <h2 className='rightTitle'>Subscribe our weekly subscription</h2>
                <Form form={form} onChange={handleChange} className='input_box'>
                    <Form.Item
                        name="email_id"
                        rules={rules.email()}
                    >
                        <Input

                            placeholder="Enter you're eamil id"
                            type="text"
                            name="email_id"

                        />
                    </Form.Item>

                    <button className='sendEmail' type='submit'>
                        <img src={Email} alt='email' />
                    </button>
                </Form>
            </div>
            {tags.length > 0 &&
                <>
                    <Divider />
                    <div className='tags'>
                        <h2 className='rightTitle'>Tags</h2>
                        <div className='allTags'>
                            {tags.map((item: any, index: number) => {
                                return (
                                    <div className='tagasName' key={index}>
                                        {item}
                                    </div>
                                )
                            })}

                        </div>

                    </div>
                </>
            }
        </div>

    )
}

export default BlogDetailsRight