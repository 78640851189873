import React, { useState } from "react";
import BannerImg from "../../../../assets/images/banner_img_1.png";
import { Button, Col, Row } from "antd";
// import { AnimationOnScroll } from "react-animation-on-scroll";
import SVGIcon from "../../../../utils/SVGIcon";
import Dotted_1 from "../../../../assets/images/dotted_1.png";
import DemoModal from "../../../layouts/MainLayout/elements/DemoModal";
import { Link } from "react-router-dom";

interface MyComponent {
  bannerData: any;
}

const Banner: React.FC<MyComponent> = ({ bannerData }) => {
  const [openDemoModal, setOpenDemoModal] = useState<boolean>(false);

  const { title, tag, content, button } = bannerData;

  const handleDemoModal = () => {
    setOpenDemoModal(!openDemoModal);
  };

  return (
    <>
      <section id="banner">
        <img src={Dotted_1} alt="" className="Dotted_1" />
        <div className="container">
          <Row gutter={20} align={"middle"}>
            <Col
              xs={{ span: 24, order: 2 }}
              md={{ span: 14, order: 1 }}
              lg={12}
            >
              {/* <AnimationOnScroll animateIn="animate__fadeInLeft"> */}
              <div className="contentSection">
                <strong className="titleTag leftLine">{tag}</strong>
                <h1 className="mainHeading">{title}</h1>
                <p>{content}</p>

                {button?.map((item: any, index: number) => {
                  return item.id === "demo" ? (
                    <Button
                      key={item.id}
                      className="buttonLink"
                      onClick={() => {
                        handleDemoModal();
                      }}
                    >
                      {item.name}
                      <SVGIcon icon="rightTopArrow" />
                    </Button>
                  ) : (
                    <Link key={index} to={item.link} className="buttonLink">
                      {item.name}
                      <SVGIcon icon="rightTopArrow" />
                    </Link>
                  );
                })}
              </div>
              {/* </AnimationOnScroll> */}
            </Col>
            <Col
              xs={{ span: 24, order: 1 }}
              md={{ span: 10, order: 2 }}
              lg={12}
            >
              {/* <AnimationOnScroll animateIn="animate__fadeInRight"> */}
              <div className="imageSection">
                <img src={BannerImg} alt="" className="bannerImg" />
              </div>
              {/* </AnimationOnScroll> */}
            </Col>
          </Row>
        </div>
      </section>
      <DemoModal
        openDemoModal={openDemoModal}
        closeDemoModal={handleDemoModal}
      />
    </>
  );
};
export default Banner;
