import React, { useEffect } from "react";

import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { Button, Col, Form, Row } from "antd";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store/app";
import { assignErrorToInput } from "../../../../store/api";
import { Notification } from "../../../../config/Global";
import { randomnumbersubstring } from "../../../../utils/commonFunctions";
import SVGIcon from "../../../../utils/SVGIcon";
import { doContactSubmit } from "../../Contact/utils/ContactSlice";
import { ContactRequest } from "../../Contact/Validation";

const FormComponents: React.FC = () => {
  const [form] = Form.useForm();

  const dispatch: AppDispatch = useDispatch();

  const handleSubmit = (data: any) => {
    const payload = `full_name=${data.full_name}&email=${data.email}&subject=${data.subject}&message=${data.message}&form_type=2`;

    dispatch(doContactSubmit(payload))
      .then((res: any) => {

        res?.data?.NOTIFICATION.map((err: string) =>
          Notification.success({
            message: err,
          })
        );
        form.resetFields();
      })
      .catch((error: any) => {

        assignErrorToInput(form, error?.NOTIFICATION);
        error?.response?.data?.NOTIFICATION.map((err: string) =>
          Notification.error({
            message: err,
          })
        );
        // form.resetFields();
      });
  };

  const resetCaptcha = () => {
    form.setFieldsValue({ captcha: randomnumbersubstring() });
  };

  useEffect(() => {
    form.setFieldsValue({ captcha: randomnumbersubstring() });
  }, [form]);

  return (
    <div className="contactForm">
      <FormBox form={form} onFinish={handleSubmit}>
        <Row gutter={10} align={"middle"}>
          <Col xs={24}>
            <h3 className="title">Contact Us</h3>
            <p className="note">Fields marked with an * are required</p>
          </Col>
          <Col xs={24}>
            <InputBox.Text
              required
              label="Your Name *"
              name="full_name"
              rules={ContactRequest().full_name("Your Name")}
              placeholder=""
            />
          </Col>
          <Col xs={24}>
            <InputBox.Text
              required
              label="Your Email *"
              name="email"
              rules={ContactRequest().email("Email")}
              placeholder=""
            />
          </Col>
          <Col xs={24}>
            <InputBox.Text
              required
              label="Subject *"
              name="subject"
              rules={ContactRequest().subject("Subject")}
              placeholder=""
            />
          </Col>
          <Col xs={24}>
            <InputBox.TextArea
              required
              label="Your Message *"
              name="message"
              rules={ContactRequest().message("Your Massage")}
              placeholder=""
            />
          </Col>
          <Col xs={24} md={8}>
            <div className="captchaLeft">
              <InputBox.Text
                // label="Captcha"
                name="captcha"
                className="captchaCode"
                disabled
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
              <Button
                type="link"
                className="resetCaptcha autoWidth autoHeight"
                onClick={() => resetCaptcha()}
              >
                <SVGIcon icon="reset" />
              </Button>
            </div>
          </Col>
          <Col xs={24} md={10}>
            <div className="input_group">
              <InputBox.Text
                name="re_captcha"
                id="re_captcha"
                placeholder="Captcha"
                required
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                rules={ContactRequest().re_captcha("Captcha")}
              />
            </div>
          </Col>
          <Col xs={24} md={6}>
            <Button type="primary" htmlType="submit" className="submit" title="Submit">
              Submit
            </Button>
          </Col>
        </Row>
      </FormBox>
    </div>
  );
};

export default FormComponents;
