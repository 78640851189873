import React from "react";

import MapBg from "../../assets/images/map_bg.png";
// import Photo1 from "../../assets/images/photo.png";
import Slider from "react-slick";

import "../../assets/styles/commonSections/testimonial.less";
import SVGIcon from "../../utils/SVGIcon";

const SlickArrowLeft = ({ currentslide, ...props }: any) => (
  <button
    {...props}
    className={
      "slick-prev slick-arrow" + (currentslide === 0 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentslide === 0 ? true : false}
    type="button"
  >
    <svg
      className="img"
      xmlns="http://www.w3.org/2000/svg"
      // fill="black"
      viewBox="0 0 48 48"
      id="arrow-left"
    >
      <path d="M17.85 35.25 6.6 23.95 17.85 12.7l1.65 1.6-8.5 8.55h30.4v2.25H11l8.5 8.5Z" />
    </svg>
  </button>
);
const SlickArrowRight = ({ currentslide, slidecount, ...props }: any) => (
  <button
    {...props}
    className={
      "slick-next slick-arrow" +
      (currentslide === slidecount - 1 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentslide === slidecount - 1 ? true : false}
    type="button"
  >
    <svg
      className="img"
      xmlns="http://www.w3.org/2000/svg"
      // fill="black"
      viewBox="0 0 48 48"
      id="arrow-left"
    >
      <path d="M17.85 35.25 6.6 23.95 17.85 12.7l1.65 1.6-8.5 8.55h30.4v2.25H11l8.5 8.5Z" />
    </svg>
  </button>
);

const Testimonial: React.FC = () => {
  const settings = {
    dots: false,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    autoplay: true,
    centerMode: true,
    centerPadding: "60px",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerPadding: "00",
        },
      },
    ],
  };

  const testimonialItem = (testimonial: any) => (
    <div className="testimonialItem" key={testimonial.id}>
      <p>“{testimonial.content}”</p>
      {/* <img src={Photo1} alt="" className="photo" /> */}
      <strong className="name">{testimonial.name}</strong>
      <span className="post">{testimonial.post}</span>
    </div>
  );

  const testimonialData = [
    {
      id: 1,
      content:
        "From last one year, our Department is using GPS Attendance for marking attendance online that helps make real-time entry of employees. It helps maintain discipline in our department.",
      name: "Dr. Govind Makwana",
      post: "Deputy Health Officer of South Zone, Maninagar",
    },
    {
      id: 2,
      content:
        "I am using GPS attendance app from last one year and it works very smoothly. Now, our entire department is working with smooth management. It allows tracking every single employee.",
      name: "Harpalsinh Zala",
      post: "Additional City Engineer of West Zone, Naranpura",
    },
  ];

  return (
    <section id="Testimonial">
      <div
        className="sliderContainer"
        style={{ backgroundImage: `url(${MapBg})` }}
      >
        <SVGIcon icon="qoute" className="qoute" />
        <Slider {...settings}>
          {testimonialData?.map((testimonial: any) =>
            testimonialItem(testimonial)
          )}
        </Slider>
      </div>
      <div className="bubbles">
        <span className="bubble one"></span>
        <span className="bubble two"></span>
        <span className="bubble three"></span>
        <span className="bubble four"></span>
        <span className="bubble five"></span>
      </div>
    </section>
  );
};
export default Testimonial;
