import { Helmet } from "react-helmet-async";

const SEOHeader = ({pageMetatag}:any) => {
  const { title, description, keyword } = pageMetatag;

  const defaultMetatag = {
    title: "Discover the Latest in Civic Technology: EverythingCivic",
    description:
      "Dive into our blog for expert perspectives on civic technology, community empowerment, and the latest trends driving social change.",
    keyword: "digital signage software, Digital signage solutions",
  };

  return (
    <Helmet prioritizeSeoTags>
      <title>{title ?? defaultMetatag.title ?? defaultMetatag.title}</title>
      <meta name="description" content={description ?? defaultMetatag.description} />
      <meta name="keyword" content={keyword ?? defaultMetatag.keyword} />
      <link rel="canonical" href={window.location.href} />
    </Helmet>
  );
};

export default SEOHeader;

