import React from 'react'
import PricingTab from './Components/PricingTab'
import "../../../assets/styles/modules/pricing.less"
import InnerBanner from '../../../components/Sections/InnerBanner';

const Price = () => {

  return (
    <>
      <InnerBanner title='Pricing' />
      <section className="pricing_main_section">
        <div className="container">

          <div className="heading">
            <h2>Get Started With Our New Plans</h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
          </div>

          <PricingTab />
        </div>
      </section>

    </>
  )
}

export default Price
