import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../../../store/app";
import axios from "axios";

interface ContactState {
  appLoading: boolean;
  isLoading: boolean;
}

const initialState = {
  appLoading: true,
  isLoading: false,
} as ContactState;

const ContactSlice = createSlice({
  name: "CONTACT",
  initialState,
  reducers: {
    setAppLoading: (state, action: PayloadAction<boolean>) => {
      state.appLoading = action.payload;
    },
    start: (state) => {
      state.isLoading = true;
    },
  },
});

export const { setAppLoading, start } = ContactSlice.actions;

const headers = {
  accept: "application/json",
  fromapp: 4,
  apikey: "GITEX@8RGIP28KTR2023",
};
const APIURL = "https://api-admin.everythingcivic.com/api/GITEX/";
// const LOCALAPIURL = "http://192.168.1.156/311-crm-api/public/api/GITEX/";

export const doContactSubmit =
  (payload: any): AppThunk<any> =>
  async (dispatch) => {
    try {
      dispatch(start());
      const response = await axios.post(APIURL + "send_email", payload, {
        headers: headers,
      });
      setAppLoading(false);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

const ContactSliceReducer = ContactSlice.reducer;
export default ContactSliceReducer;
