import { Tabs } from "antd";
import React from "react";
import price from "../Price.json"
import PricingTable from "./PricingTable";



const PricingTab: React.FC = () => {

	const onChange = (key: string) => {
		console.log(key);
	};
	const TabPane = Tabs.TabPane;


	return (
		<section className="pricing_tab">
			<Tabs
				defaultActiveKey="1"
				onChange={onChange} >
				{price?.plans.map((item: any) => {
					return (
						<TabPane tab={item.name} key={item.id}>
							<PricingTable planPrice={item} />
						</TabPane>
					)
				})}
			</Tabs>
		</section>
	);
};

export default PricingTab;
