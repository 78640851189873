import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../../store/app";
import api from "../../../../store/api";
import API_URL from "../../../../config/ApiUrl";

interface blogState {
    blogData: null,
    loading: boolean,
    pagination: any
}
const initialState = {
    blogData: null,
    loading: false,
    pagination: {
        page: 1,
        per_page: 9
    }
} as blogState


const blogSlice = createSlice({
    name: "Blog",
    initialState,
    reducers: {
        setBlogData: (state, action) => {
            state.blogData = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        }

    }
})


export const { setBlogData, setLoading, setPagination } = blogSlice.actions
export const getBlogData = (data: any): AppThunk<any> =>

    async (dispatch) => {
        try {
            const response = await api.get(API_URL.BLOG(data))
            dispatch(setLoading(false))
            dispatch(setBlogData(response))
            return Promise.resolve(response.data);
        }
        catch (error: any) {
            return Promise.reject(error.data);
        }
    }


export const getBlogDetails = (data: any): AppThunk<any> =>

    async (dispatch) => {
        try {
            const response = await api.get(API_URL.BLOG_DETAILS(data))
            dispatch(setLoading(false))
            dispatch(setBlogData(response))
            return Promise.resolve(response.data);
        }
        catch (error: any) {
            return Promise.reject(error.data);
        }
    }
export default blogSlice.reducer
