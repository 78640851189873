import React from "react";
// import { AnimationOnScroll } from "react-animation-on-scroll";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import logo1 from "../../../../assets/images/logos/logo1.jpg";
import logo2 from "../../../../assets/images/logos/logo2.jpg";
import logo3 from "../../../../assets/images/logos/logo3.jpg";
import logo4 from "../../../../assets/images/logos/logo4.png";
import logo5 from "../../../../assets/images/logos/logo5.png";
import logo6 from "../../../../assets/images/logos/logo6.png";
import logo7 from "../../../../assets/images/logos/logo7.png";
import logo8 from "../../../../assets/images/logos/logo8.png";
import logo9 from "../../../../assets/images/logos/logo9.jpg";
import logo10 from "../../../../assets/images/logos/logo10.png";
import logo11 from "../../../../assets/images/logos/logo11.png";
import logo12 from "../../../../assets/images/logos/logo12.png";
import logo13 from "../../../../assets/images/logos/logo13.png";
import logo14 from "../../../../assets/images/logos/logo14.png";
import logo15 from "../../../../assets/images/logos/logo15.png";
import logo16 from "../../../../assets/images/logos/logo16.png";
import logo17 from "../../../../assets/images/logos/logo17.png";

const LogoSlider: React.FC = () => {
  const setting = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const settingsRow1 = {
    ...setting,
  };
  const settingsRow2 = {
    ...setting,
    rtl: true,
  };

  return (
    <section id="LogoSlider">
      <div className="container">
        {/* <AnimationOnScroll animateIn="animate__fadeInLeft" offset={300}> */}
        <Slider {...settingsRow1} className="logoList">
          <img className="logoItem" src={logo1} alt="" />
          <img className="logoItem" src={logo2} alt="" />
          <img className="logoItem" src={logo3} alt="" />
          <img className="logoItem" src={logo4} alt="" />
          <img className="logoItem" src={logo5} alt="" />
          <img className="logoItem" src={logo6} alt="" />
          <img className="logoItem" src={logo7} alt="" />
          <img className="logoItem" src={logo8} alt="" />
          <img className="logoItem" src={logo9} alt="" />
          <img className="logoItem" src={logo10} alt="" />
          <img className="logoItem" src={logo11} alt="" />
          <img className="logoItem" src={logo12} alt="" />
          <img className="logoItem" src={logo13} alt="" />
          <img className="logoItem" src={logo14} alt="" />
          <img className="logoItem" src={logo15} alt="" />
          <img className="logoItem" src={logo16} alt="" />
          <img className="logoItem" src={logo17} alt="" />
        </Slider>
        {/* </AnimationOnScroll> */}
        {/* <AnimationOnScroll animateIn="animate__fadeInRight" offset={300}> */}
        <Slider {...settingsRow2} className="logoList">
          <img className="logoItem" src={logo1} alt="" />
          <img className="logoItem" src={logo2} alt="" />
          <img className="logoItem" src={logo3} alt="" />
          <img className="logoItem" src={logo4} alt="" />
          <img className="logoItem" src={logo5} alt="" />
          <img className="logoItem" src={logo6} alt="" />
          <img className="logoItem" src={logo7} alt="" />
          <img className="logoItem" src={logo8} alt="" />
          <img className="logoItem" src={logo9} alt="" />
          <img className="logoItem" src={logo10} alt="" />
          <img className="logoItem" src={logo11} alt="" />
          <img className="logoItem" src={logo12} alt="" />
          <img className="logoItem" src={logo13} alt="" />
          <img className="logoItem" src={logo14} alt="" />
          <img className="logoItem" src={logo15} alt="" />
          <img className="logoItem" src={logo16} alt="" />
          <img className="logoItem" src={logo17} alt="" />
        </Slider>
        {/* </AnimationOnScroll> */}
      </div>
    </section>
  );
};
export default LogoSlider;
