import React from 'react'
import Banner from './components/Banner'
import "../../../assets/styles/modules/help.less";
import { Col, Row } from 'antd';
import FormComponents from './components/FormComponents';
import ContactDetails from './components/ContactDetails';
import ContactJson from "./contact.json";
import InstantSupport from './components/InstantSupport';

const helpAndSupport = () => {
    return (
        <section id="helpAndSupport">
            <Banner />
            <div className="container">
                <Row gutter={[30, 60]} align={"top"}>
                    <Col xs={24} lg={12}>
                        <FormComponents />
                    </Col>
                    <Col xs={24} lg={10}>
                        <ContactDetails contactData={ContactJson?.contact} />
                    </Col>
                    <Col xs={24}>
                        <InstantSupport supportData={ContactJson?.support} />
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default helpAndSupport