import React from "react";
import { Layout } from "antd";
// import useStore from "../../../store";
import { Outlet } from "react-router-dom";
import FooterView from "./FooterView";
import HeaderView from "./HeaderView";
// import Snowfall from "react-snowfall";

const MainLayout: React.FC = () => {
  return (
    <Layout className={`mainWrapper`}>
      {/* <div className="Snowfall">
        <Snowfall color={"#a5d6ff"} snowflakeCount={500}  />
      </div> */}
      <HeaderView />
      <Layout.Content className="mainContent">
        <Outlet />
      </Layout.Content>
      <FooterView />
    </Layout>
  );
};

export default MainLayout;
