import React, { useState } from 'react'
import FieldInspectionModule from "./FieldInspectionModule.json"
import "../../../assets/styles/modules/fieldInsPectionModule.less"
import banner from "../../../assets/images/banner.png"
import { Button, Col, Form, Row } from 'antd';
import cameraImage from "../../../assets/images/cameraImage.png"
import mobileImage from "../../../assets/images/mobile.png"
import uploadImage from "../../../assets/images/upload.png"
import blogDots from "../../../assets/images/BlogDetailsDot.png";
import CityImg from "../../..//assets/images/city_bg1.png";
import reportImage from "../../../assets/images/reportImage.png";
import RequiredRightCircle from "../../../assets/images/requirementLeftCircle.png";
import squreDots from "../../../assets/images/squreDots.png";
import featuresOfField from "../../../assets/images/FeaturesofFieldBg.png"
import { ArrowUpOutlined } from "@ant-design/icons";
import { ReactComponent as FieldHand } from "../../../assets/images/icons/handField.svg";
import { ReactComponent as HorizontalLines } from "../../../assets/images/horizontalLines.svg";
import { ReactComponent as FeaturesofFieldLine } from "../../../assets/images/FeaturesofFieldLine.svg";
import SVGIcon from '../../../utils/SVGIcon';
import MapBg from "../../../assets/images/map_bg.png";
import Slider from "react-slick";
import { FormBox, InputBox } from '../../../components/AntdAddons';
import { AppDispatch } from '../../../store/app';
import { doContactSubmit } from '../Contact/utils/ContactSlice';
import { Notification } from '../../../config/Global';
import { assignErrorToInput } from '../../../store/api';
import { useDispatch } from 'react-redux';
import { ContactRequest } from '../Contact/Validation';
import sendBtn from "../../../assets/images/demoSend.png"
import demoLine from "../../../assets/images/demoLine.png"
import { ReactComponent as RequiredTwo } from "../../../assets/images/require2.svg"
import { ReactComponent as CitizensDots } from "../../../assets/images/CitizensDots.svg"
import citizenLeftOne from "../../../assets/images/CitizensSolutionImageleftOne.png"
import citizenLeftTwo from "../../../assets/images/CitizensSolutionImageLeftTwo.png"
import citizenRight from "../../../assets/images/CitizensSolutionImageRight.png"
import DemoModal from '../../layouts/MainLayout/elements/DemoModal';
import { Link } from 'react-router-dom';

const FieldInsPectionModule = () => {
	const solutionforCitizens = FieldInspectionModule?.solutionforCitizens;
	const crmSection = FieldInspectionModule?.crmSection;
	const weAreReady = FieldInspectionModule?.weAreReady;
	const demo = FieldInspectionModule?.demo
	const [form] = Form.useForm();
	const images = [
		cameraImage,
		mobileImage,
		uploadImage,
	]
	const [openDemoModal, setOpenDemoModal] = useState<boolean>(false);

	const handleDemoModal = () => {
		setOpenDemoModal(!openDemoModal);
	};
	const dispatch: AppDispatch = useDispatch();

	const handleSubmit = (data: any) => {
		const payload = `full_name=${data.full_name}&email=${data.email}&subject=${data.subject}&message=${data.message}`;

		dispatch(doContactSubmit(payload))
			.then((res: any) => {

				res?.data?.NOTIFICATION.map((err: string) =>
					Notification.success({
						message: err,
					})
				);
				form.resetFields();
			})
			.catch((error: any) => {

				assignErrorToInput(form, error?.NOTIFICATION);
				error?.response?.data?.NOTIFICATION.map((err: string) =>
					Notification.error({
						message: err,
					})
				);
				// form.resetFields();
			});
	};
	const SlickArrowLeft = ({ currentslide, ...props }: any) => (
		<button
			{...props}
			className={
				"slick-prev slick-arrow" + (currentslide === 0 ? " slick-disabled" : "")
			}
			aria-hidden="true"
			aria-disabled={currentslide === 0 ? true : false}
			type="button"
		>
			<svg
				className="img"
				xmlns="http://www.w3.org/2000/svg"
				// fill="black"
				viewBox="0 0 48 48"
				id="arrow-left"
			>
				<path d="M17.85 35.25 6.6 23.95 17.85 12.7l1.65 1.6-8.5 8.55h30.4v2.25H11l8.5 8.5Z" />
			</svg>
		</button>
	);
	const SlickArrowRight = ({ currentslide, slidecount, ...props }: any) => (
		<button
			{...props}
			className={
				"slick-next slick-arrow" +
				(currentslide === slidecount - 1 ? " slick-disabled" : "")
			}
			aria-hidden="true"
			aria-disabled={currentslide === slidecount - 1 ? true : false}
			type="button"
		>
			<svg
				className="img"
				xmlns="http://www.w3.org/2000/svg"
				// fill="black"
				viewBox="0 0 48 48"
				id="arrow-left"
			>
				<path d="M17.85 35.25 6.6 23.95 17.85 12.7l1.65 1.6-8.5 8.55h30.4v2.25H11l8.5 8.5Z" />
			</svg>
		</button>
	);

	const settings = {
		dots: false,
		arrows: true,
		prevArrow: <SlickArrowLeft />,
		nextArrow: <SlickArrowRight />,
		autoplay: true,
		centerMode: true,
		centerPadding: "60px",
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		swipeToSlide: true,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					centerPadding: "00",
				},
			},
		],
	};

	const testimonialItem = (testimonial: any) => (
		<div className="testimonialItem" key={testimonial.id}>
			<p>“{testimonial.content}”</p>
			<strong className="name">{testimonial.name}</strong>
			<span className="post">{testimonial.post}</span>
		</div>
	);

	const testimonialData = [
		{
		  id: 1,
		  content:
			"From last one year, our Department is using GPS Attendance for marking attendance online that helps make real-time entry of employees. It helps maintain discipline in our department.",
		  name: "Dr. Govind Makwana",
		  post: "Deputy Health Officer of South Zone, Maninagar",
		},
		{
		  id: 2,
		  content:
			"I am using GPS attendance app from last one year and it works very smoothly. Now, our entire department is working with smooth management. It allows tracking every single employee.",
		  name: "Harpalsinh Zala",
		  post: "Additional City Engineer of West Zone, Naranpura",
		},
	  ];

	return (
		<div id='FieldInsPectionModule'>
			<section className='banner' style={{ backgroundImage: `URL(${banner})` }}>
				<h1 className="title">
					{FieldInspectionModule.FieldInsPectionModule.title}
				</h1>
			</section>
			<section className='SolutionForCitizens'>
				<img src={blogDots} alt='blogDots' className='Dotted_1' />
				<div className='container'>
					<Row gutter={[30, 30]} >
						<Col xs={24} lg={12}>
							<div className='citizensSectionWrap'>
								<div className='citizensSectionLeft'>
									<div className='citizensImageBlogOne'>
										<img src={citizenLeftOne} alt='citizenRightTwo' />
									</div>
									<div className='citizensImageBlogTwo'>
										<img src={citizenLeftTwo} alt='citizenLeftTwo' />
									</div>
								</div>

								<div className='citizensSectionRight'>
									<img src={citizenRight} alt='citizenRight' />
								</div>
							</div>
						</Col>
						<Col xs={24} lg={12}>
							<span className="subTitle">{solutionforCitizens.subtitle}</span>
							<h2 className="innerTitle">{solutionforCitizens.title}</h2>
							<div className="content" dangerouslySetInnerHTML={{ __html: solutionforCitizens.content }}></div>
							{solutionforCitizens?.innerContent.map((item: any, index: number) => {
								return (
									<div key={index} className='citizensPicture'>
										<div className='citizensImages'>
											<img src={images[index]} alt={item.key} />
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="28" viewBox="0 0 16 28" fill="none">
												<path d="M7.29289 27.2071C7.68342 27.5976 8.31658 27.5976 8.70711 27.2071L15.0711 20.8431C15.4616 20.4526 15.4616 19.8195 15.0711 19.4289C14.6805 19.0384 14.0474 19.0384 13.6569 19.4289L8 25.0858L2.34315 19.4289C1.95262 19.0384 1.31946 19.0384 0.928933 19.4289C0.538408 19.8195 0.538408 20.4526 0.928933 20.8431L7.29289 27.2071ZM9 26.5L9 8.74228e-08L7 -8.74228e-08L7 26.5L9 26.5Z" fill="#79C423" />
											</svg>
										</div>
										<div className='citizensContent'>
											<h3>{item.title}</h3>
											<p>{item.content}</p>
										</div>
									</div>
								)
							})}
						</Col>
					</Row>
				</div>
				<img src={CityImg} alt="" className="CityImg" />
			</section>
			<section className='reportSection'>
				<div className='container'>
					<div className='titleContent'>
						<span className='reportSubTitle'>
							{crmSection.subtitle}
						</span>
						<h2 className="reportTitle">
							{crmSection.title}
						</h2>
						<p className='reportContent'>{crmSection.content}</p>
					</div>
					<div className='crmService'>
						{crmSection?.management?.length > 0 && crmSection.management.map((item: any, index: number) => {
							return (
								<div key={index} className='crmServiceContent'>
									<div className='crmServiceContentImage'>
										<img src={reportImage} alt={item.key} />
									</div>
									<h3 className='crmServiceTitle'>{item.title}</h3>
									<ul className='crmServiceList'>
										{item?.list.length > 0 && item?.list.map((item: any) => {
											return (
												<li>
													<ArrowUpOutlined />
													{item.content}
												</li>
											)
										})
										}
									</ul>
								</div>
							)
						})}

					</div>
					<div className="requiredRightCircle">
						<img src={RequiredRightCircle} alt="RequiredLeftCircle" />
					</div>
					<div className="squreDotsLeft">
						<img src={squreDots} alt="RequiredLeftCircle" />
					</div>
					<div className="squreDotsRight">
						<img src={squreDots} alt="RequiredLeftCircle" />
					</div>
					<div className='firstLine'>
						<svg xmlns="http://www.w3.org/2000/svg" width="970" height="628" viewBox="0 0 970 628" fill="none">
							<path d="M1032 1C1029.56 40.5466 970.407 133.705 753.363 189.968C482.059 260.295 219.31 315.335 3 626" stroke="white" stroke-opacity="0.04" stroke-width="7" />
						</svg>
					</div>
					<div className='secondLine'>
						<svg xmlns="http://www.w3.org/2000/svg" width="1920" height="381" viewBox="0 0 1920 381" fill="none">
							<path d="M2021.75 3.04193C1940.75 48.614 1721.92 124.348 1494.6 62.7073C1210.45 -14.3434 1029.78 201.951 940.145 278.737C850.514 355.523 739.732 413.004 389.704 350.88C39.6764 288.756 96.5348 264.737 -30.0018 332" stroke="white" stroke-opacity="0.04" stroke-width="7" />
						</svg>
					</div>
				</div>

			</section>
			<section className='weAreReady'>
				<div className='container'>
					<div className='weAreReadyTitles'>
						<span className="subTitle">{weAreReady.subtitle}</span>
						<h2 className="title">{weAreReady.title}</h2>
						<div className="content" dangerouslySetInnerHTML={{ __html: weAreReady.content }}></div>
					</div>
					<Row gutter={[30, 30]} className='inspection'>
						<Col xs={24} md={12} lg={12} >
							<h3 className='tags'>{weAreReady.Comprehensive.title}</h3>
							<p className='inspectionContent'>{weAreReady.Comprehensive.content}</p>
						</Col>
						<Col xs={24} md={12} lg={12}>
							<h3 className='tags'>{weAreReady.routine.title}</h3>
							<p className='inspectionContent'>{weAreReady.routine.content}</p>
						</Col>
					</Row>
				</div>
			</section>
			<section className='featuresOfField' style={{ backgroundImage: `URL(${featuresOfField})` }}>
				<div className='featuresOfFieldLines'>

					<FeaturesofFieldLine />
				</div>
				<div className='container'>
					<div className='leftDots'>
						<HorizontalLines />
					</div>
					<div className='fieldInspectionContent'>
						<div className='fieldInspectionimg'>
							<FieldHand />
							<h2>{FieldInspectionModule.inspectionFeatures.title}</h2>
							<Link to={'/field-work-monitoring-module-inner'}
								title="View Details" className="ant-btn ant-btn-default buttonLink primaryColor outline demoBtn"><span>{FieldInspectionModule.inspectionFeatures.link}</span></Link>
						</div>
					</div>
				</div>
				<div className='rightDots'>
					<HorizontalLines />
				</div>
			</section>
			<section id="Testimonial">
				<div
					className="sliderContainer"
					style={{ backgroundImage: `url(${MapBg})` }}
				>
					<SVGIcon icon="qoute" className="qoute" />
					<Slider {...settings}>
						{testimonialData?.map((testimonial: any) =>
							testimonialItem(testimonial)
						)}
					</Slider>
				</div>
				<div className="bubbles">
					<span className="bubble one"></span>
					<span className="bubble two"></span>
					<span className="bubble three"></span>
					<span className="bubble four"></span>
					<span className="bubble five"></span>
				</div>
			</section>
			<section className='demoForm'>
				<div className='reportSection'>
					<div className='featuresOfFieldLines'>
						<img src={demoLine} alt='demoLine' />
					</div>
					<div className='miniContainer'>
						<Row gutter={[30, 30]} >
							<Col xs={24} lg={14}>
								{demo?.management.map((item: any, index: number) => {
									return (
										<div key={index} className='crmServiceContent'>
											<h2 className='crmServiceTitle'>{item.title}</h2>
											<ul className='crmServiceList'>
												{item?.list.length > 0 && item?.list.map((item: any) => {
													return (
														<li>
															<ArrowUpOutlined />
															{item.content}
														</li>
													)
												})
												}
											</ul>
										</div>
									)
								})}
							</Col>
							<Col xs={24} lg={10}>
								<div className="contactForm">
									<FormBox form={form} onFinish={handleSubmit}>
										<h2 className='crmServiceTitle'>Request a Demo</h2>
										<div className='nameInput'>
											<InputBox.Text
												required
												label="Your Name *"
												name="full_name"
												rules={ContactRequest().full_name("Your Name")}
												placeholder="Enter your name"
											/>

											<InputBox.Text
												required
												label="Mobile No *"
												name="Mobile No"
												// rules={ContactRequest().email("Email")}
												placeholder="Enter your mobile no"
											/>
										</div>
										<InputBox.Text
											required
											label="Your Email *"
											name="email"
											rules={ContactRequest().email("Email")}
											placeholder="Enter your email id"
										/>
										<InputBox.TextArea
											required
											label="Write Comment *"
											name="message"
											rules={ContactRequest().message("Your Massage")}
											placeholder="Enter your Comment"
											rows={1}
										/>
										<Button
											htmlType='submit'
										>Submit
											<img src={sendBtn} alt="send" />
										</Button>
									</FormBox>
								</div>
							</Col>
						</Row>
						<div className='forCitizens'>
							<div className="requiredTwo">
								<RequiredTwo />
							</div>
							<div className='CitizensDots'>
								<CitizensDots />
							</div>
							<span className='subTitle'>{FieldInspectionModule.solutionCitizens.subtitle}</span>
							<h2>{FieldInspectionModule.solutionCitizens.title}</h2>
							<Button
								onClick={() => {
									handleDemoModal();
								}}
								title="View Details" className="ant-btn ant-btn-default buttonLink primaryColor outline demoBtn"><span>{FieldInspectionModule.solutionCitizens.button}</span></Button>


							<div className='CitizensDotsBottom'>
								<CitizensDots />
							</div>

							<div className="requiredRightCircle">
								<svg xmlns="http://www.w3.org/2000/svg" width="242" height="241" viewBox="0 0 242 241" fill="none">
									<g opacity="0.31">
										<circle cx="121.055" cy="120.5" r="120.5" fill="url(#paint0_linear_829_3894)" />
										<circle cx="121.055" cy="120.5" r="120.5" fill="url(#paint1_linear_829_3894)" />
										<circle cx="121.471" cy="124.892" r="77.0294" transform="rotate(106.834 121.471 124.892)" fill="url(#paint2_linear_829_3894)" />
										<circle cx="121.471" cy="124.892" r="77.0294" transform="rotate(106.834 121.471 124.892)" fill="url(#paint3_linear_829_3894)" />
									</g>
									<defs>
										<linearGradient id="paint0_linear_829_3894" x1="121.055" y1="0" x2="121.055" y2="241" gradientUnits="userSpaceOnUse">
											<stop stop-color="white" />
											<stop offset="1" stop-color="white" stop-opacity="0" />
										</linearGradient>
										<linearGradient id="paint1_linear_829_3894" x1="121.055" y1="0" x2="121.055" y2="241" gradientUnits="userSpaceOnUse">
											<stop stop-color="white" />
											<stop offset="1" stop-color="white" stop-opacity="0" />
										</linearGradient>
										<linearGradient id="paint2_linear_829_3894" x1="121.471" y1="47.8628" x2="121.471" y2="201.922" gradientUnits="userSpaceOnUse">
											<stop stop-color="white" />
											<stop offset="1" stop-color="white" stop-opacity="0" />
										</linearGradient>
										<linearGradient id="paint3_linear_829_3894" x1="121.471" y1="47.8628" x2="121.471" y2="201.922" gradientUnits="userSpaceOnUse">
											<stop stop-color="white" />
											<stop offset="1" stop-color="white" stop-opacity="0" />
										</linearGradient>
									</defs>
								</svg>
							</div>
						</div>
					</div>
				</div>
				<div className="squreDotsRight">
					<img src={squreDots} alt="RequiredLeftCircle" />
				</div>
			</section>
			<DemoModal
				openDemoModal={openDemoModal}
				closeDemoModal={handleDemoModal}
			/>
		</div>
	)
}

export default FieldInsPectionModule