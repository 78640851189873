import React from "react";
import SVGIcon from "../../../../utils/SVGIcon";
import { Link } from "react-router-dom";

const ContactDetails: React.FC<any> = ({ contactData }) => {
  const { section, title, address, support, email } = contactData;

  const iconList = (icon: string, details: any) => (
    <div className="iconList">
      <div className="icon">
        <SVGIcon icon={icon} />
      </div>
      <div className="details">
        {details.name ? <strong>{details.name}</strong> : null}
        {details.type === "tel" ? (
          <Link to={`tel:${details.link}`} title={details.link}>{details.link}</Link>
        ) : (
          <Link to={`mailto:${details.link}`} title={details.link}>{details.link}</Link>
        )}
      </div>
    </div>
  );

  return (
    <div className="contactDetails">
      <h2 className="title">{title}</h2>
      <h4 className="section">{section}</h4>
      <p className="address">{address}</p>
      <hr />

      {/* {iconList("phone", inquiry)} */}
      {iconList("phone", support)}
      {iconList("email", email)}
    </div>
  );
};

export default ContactDetails;
