import { Col } from "antd";
import React from "react";
import RequirementImg from "../../assets/images/requirement.png";
import { ReactComponent as RequiredOne } from "../../assets/images/requireCircle.svg";
import { ReactComponent as RequiredTwo } from "../../assets/images/require2.svg";
import RequiredLeftCircle from "../../assets/images/requirementLeftCircle.png";
import RequiredRightCircle from "../../assets/images/requirementLeftCircle.png";
import { Link } from "react-router-dom";

const RequirementSection = (props: any) => {
  const { title, content, subTitle } = props;
  return (
    <>
      <div className="requiredOne">
        <RequiredOne />
      </div>
      <div className="requiredTwo">
        <RequiredTwo />
      </div>
      <div className="requiredLeftCircle">
        <img src={RequiredLeftCircle} alt="RequiredLeftCircle" />
      </div>
      <div className="requiredRightCircle">
        <img src={RequiredRightCircle} alt="RequiredLeftCircle" />
      </div>
      <Col className="requirementInnerContent">
        <h2>{title ?? title}</h2>
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
        <Link
          to={"/contact-us"}
          rel="noreferrer"
          className="buttonLink primaryColor"
        >
          {subTitle ?? subTitle}
        </Link>
      </Col>
      <Col className="requirementImage">
        <img src={RequirementImg} alt="Requirement" />
      </Col>
    </>
  );
};

export default RequirementSection;
