import { Col, Row } from "antd";
import banner from "../../../assets/images/banner.png";
import "../../../assets/styles/modules/about.less";
import AboutData from "./About.json";
import aboutFirst from "../../../assets/images/aboutFirst.png";
import aboutSecond from "../../../assets/images/aboutSecond.png";
import CountUp from "react-countup";
import certificates from "../../../assets/images/Certified.png";
import trophhy from "../../../assets/images/trophy.png";
import smip from "../../../assets/images/smip.png";
import abhim from "../../../assets/images/abhim.png";
import anantSharma from "../../../assets/images/AnantSharma.png";

const About = () => {
  const sirImg = [smip, abhim, anantSharma];

  return (
      <div id="aboutUsPage">
        <section
          className="banner"
          style={{ backgroundImage: `URL(${banner})` }}
        >
          <h1 className="title">About Us</h1>
        </section>
        <section className="whoWeAre">
          <div className="container">
            {AboutData?.Whoweare?.map((item: any) => {
              return (
                <Row gutter={[30, 30]} key={item.id}>
                  <Col xs={24} lg={12}>
                    <div className="leftContent">
                      {/* <h5 className="subTitle">{item.subtitle}</h5> */}
                      <h2 className="title">{item.title}</h2>
                      <div
                        className="content"
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      ></div>
                    </div>
                  </Col>
                  <Col xs={24} lg={12}>
                    <div className="rightContent">
                      <div className="aboutBigImg">
                        <img
                          src={aboutFirst}
                          alt="aboutFirst"
                          className="aboutFirst"
                        />
                      </div>
                      <div className="imageContent">
                        <div className="experience">
                          <p className="experienceContent">
                            <span>{item.experience}</span>Year experience{" "}
                          </p>
                        </div>
                        <div className="experienceImage">
                          <img
                            src={aboutSecond}
                            alt="aboutSecond"
                            className="aboutSecond"
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              );
            })}
            <section className="counter">
              {AboutData.count.map((item) => {
                return (
                  <div className="counterSection" key={item.id}>
                    <CountUp
                      start={0}
                      end={item.num}
                      delay={0.5}
                      suffix={"+"}
                      className="counterUp"
                    />
                    <p>{item.title}</p>
                  </div>
                );
              })}
            </section>
          </div>
        </section>
        <section className="prideSection">
          <img src={trophhy} alt="tropy" className="tropy" />
          <div className="container">
            <Row gutter={[30, 60]}>
              <Col xs={24} lg={12}>
                {AboutData?.pride && (
                  <h5 className="subTitle">{AboutData.pride.subtitle}</h5>
                )}
                {AboutData?.pride?.title && (
                  <h2 className="title">{AboutData.pride.title}</h2>
                )}
                {AboutData?.pride?.content && (
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{
                      __html: AboutData.pride.content,
                    }}
                  ></div>
                )}
              </Col>
              <Col xs={24} lg={12}>
                <div className="certified">
                  {AboutData?.pride?.certified &&
                    AboutData?.pride?.certified.map((item: any) => {
                      return (
                        <div className="certifiedInnerContent" key={item.id}>
                          <div className="certifiedImgTitle">
                            {item?.img ? (
                              <img
                                src={item.img}
                                alt="certificates"
                                className="certifiedImg"
                              />
                            ) : (
                              <img
                                src={certificates}
                                alt="certificates"
                                className="certifiedImg"
                              />
                            )}
                            {item?.title && (
                              <h3 className="certifiedTitle">{item?.title}</h3>
                            )}
                          </div>
                          {item?.content && (
                            <div
                              className="certifiedContent"
                              dangerouslySetInnerHTML={{
                                __html: item?.content,
                              }}
                            ></div>
                          )}
                        </div>
                      );
                    })}
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <section className="founderSection">
          <div className="container">
            {/* {AboutData?.team && <h5 className="subTitle">{AboutData.team.subtitle}</h5>} */}
            {AboutData?.team?.title && (
              <h2 className="title">{AboutData.team.title}</h2>
            )}
            <div className="founderContent">
              {AboutData?.team?.founder &&
                AboutData?.team?.founder.map((item: any, index: number) => {
                  return (
                    <div className="founderInnerContent">
                      <div className="founderImg">
                        <img src={sirImg[index]} alt="founder" />
                      </div>

                      {item?.name && (
                        <h4 className="founderName">{item?.name}</h4>
                      )}
                      {item?.director && (
                        <p className="director">{item?.director}</p>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </section>
      </div>
  );
};

export default About;
