import { debounce } from "lodash";

export const renderNA = (data: any) => {
  return data ? data : "N/A";
};

export const validateFields = debounce((form, setDisabled) => {
  form
    .validateFields()
    .then(() => {
      setDisabled(false);
    })
    .catch(() => {
      setDisabled(true);
    });
}, 500);

// For Random number string
export const randomnumbersubstring = () => {
  // return Math.floor(100000 + Math.random() * 900000);
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = " ";
  const charactersLength = characters.length;

  function randomIntFromInterval(min: number, max: number) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  const rndInt = randomIntFromInterval(4, 4);

  for (let i = 0; i < rndInt; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
