import moment from 'moment';
import React from 'react'
import { ReactComponent as Calender } from '../../../../assets/images/icons/calendar.svg';

const BlogDetailsLeft = (props: any) => {


    return (
        <div className='blogDetail'>
            <div className='featureImg'>
                {props?.blogDetails?.image_url ? <img src={props?.blogDetails?.image_url} alt={props?.blogDetails?.title} /> : null}
                <div className='featureDate'>
                    <Calender /><span className="date">{moment(props?.blogDetails?.publish_date).format("MMM, DD YYYY")}</span>
                </div>
            </div>

            <div className='content'>
                {props?.blogDetails?.title && <h1>{props?.blogDetails?.title}</h1>}
                {props?.blogDetails?.description && <div dangerouslySetInnerHTML={{ __html: props?.blogDetails?.description }}></div>}
            </div>
        </div>
    )
}

export default BlogDetailsLeft