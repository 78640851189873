import React from "react";
import { Link } from "react-router-dom";
import { CONSTANT } from "../../../../config/Constant";

import SiteLogo from "../../../../assets/images/logo-after-Dec/logo.png";

const Logo: React.FC = () => {
  return (
    <Link
      to={CONSTANT.PAGE_LINK.HOME}
      className="siteLogo"
      title="Smartcity 311"
    >
      <img src={SiteLogo} alt="Smartcity 311" />
    </Link>
  );
};
export default Logo;
