import React from "react";
import { Col, Row } from "antd";
// import { AnimationOnScroll } from "react-animation-on-scroll";

// import Drone from "../../../../../assets/images/drone.png";
import CivicImg from "../../../../../assets/images/civic_image1.png";
import ContentSection from "./ContentSection";
import CounterSection from "./CounterSection";

interface MyComponent {
  aboutData: any;
  counterData: any;
}
const About: React.FC<MyComponent> = ({ aboutData, counterData }) => {
  return (
    <section id="About">
      {/* <AnimationOnScroll animateIn="animate__fadeInUpBig" offset={200}>
        <img src={Drone} alt="" className="drone" />
      </AnimationOnScroll> */}
      <div className="circles">
        <div className="circle1"></div>
        <div className="circle2"></div>
        <div className="circle3"></div>
      </div>

      <div className="container">
        <Row gutter={60} align={"bottom"}>
          <Col xs={24} lg={12}>
            <ContentSection aboutData={aboutData} />
          </Col>
          <Col xs={24} lg={12}>
            <CounterSection counterData={counterData} />
          </Col>
        </Row>
      </div>
      <img src={CivicImg} className="CivicImg" alt="CivicImg" />
    </section>
  );
};
export default About;
