import React from "react";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { Button, Form } from "antd";
import { AppDispatch } from "../../../../store/app";
import { useDispatch } from "react-redux";
import { doNewsletterSubscribe } from "../utils/FooterSlice";
import { assignErrorToInput } from "../../../../store/api";
import { Notification } from "../../../../config/Global";
import { FooterRequest } from "../Validation";

interface MyComponent {
  news_section: string;
}
const FooterNewsUpdatesSection: React.FC<MyComponent> = ({ news_section }) => {
  const [form] = Form.useForm();

  const dispatch: AppDispatch = useDispatch();

  const handleSubmit = () => {
    const emailData = form.getFieldValue("email");

    form
      .validateFields(["email"])
      .then(() => {
        const payload = `email=${emailData}`;
        dispatch(doNewsletterSubscribe(payload))
          .then((res: any) => {
            console.log("data form", res?.data);

            res?.data?.NOTIFICATION.map((err: string) =>
              Notification.success({
                message: err,
              })
            );
            form.resetFields();
          })
          .catch((error: any) => {
            console.log("api error", error);

            assignErrorToInput(form, error?.NOTIFICATION);
            error?.response?.data?.NOTIFICATION.map((err: string) =>
              Notification.error({
                message: err,
              })
            );
            // form.resetFields();
          });
      })
      .catch((error: any) => {
        assignErrorToInput(form, error?.errorFields);
      });
  };

  return (
    <div className="contactSection">
      <h4 className="footerTitle">City News & Updates</h4>
      <p className="summary">{news_section}</p>
      <FormBox form={form} onFinish={handleSubmit}>
        <div className="subscribeForm">
          <InputBox.Text
            required
            name="email"
            placeholder="Your Email"
            rules={FooterRequest().email("Your Email")}
          />
          <Button
            type="primary"
            htmlType="submit"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            title="Subscribe"
          >
            Subscribe
          </Button>
        </div>
      </FormBox>
    </div>
  );
};

export default FooterNewsUpdatesSection;
