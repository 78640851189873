import React from "react";
import { Col, Layout, Row } from "antd";
import Copyright from "./elements/Copyright";
import FooterJson from "./footer.json";
import FooterFirstSection from "./elements/FooterFirstSection";
import FooterBuilding from "../../../assets/images/footerBuilding.png";
import FooterContactSection from "./elements/FooterContactSection";
import FooterNewsUpdatesSection from "./elements/FooterNewsUpdatesSection";

const FooterView: React.FC = () => {
  const { summary, social_media, address, news_section } = FooterJson;
  return (
    <>
      <Layout.Footer
        id={"Contact"}
        className="mainFooter"
        style={{ backgroundImage: `url(${FooterBuilding})` }}
      >
        <div className="container">
          <Row gutter={[20, 30]} align={"top"}>
            <Col xs={24} md={12} lg={8}>
              <FooterFirstSection socialLink={social_media} summary={summary} />
            </Col>
            <Col xs={24} md={12} lg={8}>
              <FooterContactSection address={address} />
            </Col>
            <Col xs={24} md={12} lg={8}>
              <FooterNewsUpdatesSection news_section={news_section} />
            </Col>
          </Row>
        </div>
      </Layout.Footer>
      <Copyright />
    </>
  );
};

export default FooterView;
