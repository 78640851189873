import React from "react";
import SVGIcon from "../../../../utils/SVGIcon";
import { Link } from "react-router-dom";

interface MyComponent {
  address: any;
}
const FooterContactSection: React.FC<MyComponent> = ({ address }) => {
  const addressText = address && address[0];
  const emailText = address && address[1];
  const telText = address && address[2];

  return (
    <div className="contactSection">
      <h4 className="footerTitle">Contact Us</h4>
      <ul className="contact">
        <li>
          <SVGIcon icon={addressText.name} className={addressText.name} />
          <Link to={"https://maps.app.goo.gl/tQDzw4HYuZbvx3dU6"} title="Civic Solutions Pvt. Ltd" target="_blank">{addressText.text}</Link> 
        </li>
        <li>
          <SVGIcon icon={emailText.name} className={emailText.name} />
          <a href={`mailto:${emailText.link}`} title={emailText.text} target="_blank" rel="noreferrer">
            {emailText.text}
          </a>
        </li>
        <li>
          <SVGIcon icon={telText.name} className={telText.name} />
          <a href={`tel:${telText.link}`} title={telText.text} target="_blank" rel="noreferrer">
            {telText.text}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FooterContactSection;
