import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../../store/app";
import api from "../../../../store/api";
import API_URL from "../../../../config/ApiUrl";
import { failure, success } from "../../../../store/AuthSlice";
import { Notification } from "../../../../config/Global";

interface careerState {
    careerData: null,
    loading: boolean
}
const initialState = {
    careerData: null,
    loading: false
} as careerState


const careerSlice = createSlice({
    name: "Career",
    initialState,
    reducers: {
        setCareerData: (state, action) => {
            state.careerData = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        }
    }
})

export const { setCareerData, setLoading } = careerSlice.actions
export const getCareerData = (): AppThunk<any> =>
    async (dispatch) => {
        try {
            const response = await api.get(API_URL.CAREER)
            dispatch(setLoading(false))
            dispatch(setCareerData(response))
            return Promise.resolve(response.data);
        }
        catch (error: any) {
            return Promise.reject(error.data);
        }
    }
export const careerApply = (id: any, data: any): AppThunk<any> =>
    async (dispatch) => {
        try {
            const response = await api.post(API_URL.CAREERAPPLY(id), data)
            Notification.success({
                message: response?.data?.NOTIFICATION[0]
            })
            dispatch(success(response?.data?.NOTIFICATION));
            return Promise.resolve(response.data);
        } catch (error: any) {
            dispatch(failure());
            return Promise.reject(error.data?.NOTIFICATION);
        }
    }
export default careerSlice.reducer
