import Message from "../../../../config/Message";
import { validations } from "../../../../config/validations/validations";


export const rules: any = {
  
    email: () => [
        validations.pattern.email,
        validations.max.text(100),
        { required: true, message: Message().required.text() },
    ],
    

}