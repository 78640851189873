import React from "react";
import SVGIcon from "../../../../utils/SVGIcon";
import { Link } from "react-router-dom";
import { CONSTANT } from "../../../../config/Constant";
import SiteLogo from "../../../../assets/images/logo-after-Dec/logo.png";

interface MyComponent {
  summary: string;
  socialLink: any;
}
const FooterFirstSection: React.FC<MyComponent> = ({ summary, socialLink }) => {
  return (
    <div className="firstSection">
      <Link
        to={CONSTANT.PAGE_LINK.HOME}
        className="siteLogo"
        title="Smartcity 311"
      >
        <img src={SiteLogo} alt="Smartcity 311" />
      </Link>
      <p className="summary">{summary}</p>
      <ul className="social">
        {socialLink?.map((item: any, index: number) => {
          return (
            <li key={index}>
              <a href={item.link} target="_blank" rel="noreferrer">
                <SVGIcon
                  icon={item.name}
                  className={item.name === "twitter" ? "stroke" : ""}
                />
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FooterFirstSection;
