import CountUp from "react-countup";
import SVGIcon from "../../../../../utils/SVGIcon";

interface MyComponent {
  counterData: any;
}
const CounterSection: React.FC<MyComponent> = ({ counterData }) => {
  // const counter: any = (
  //   className: string,
  //   icon: string,
  //   value: number,
  //   title: string
  // ) => (
  //   <div className={`counter ${className}`}>
  //     <div className="details">
  //       <SVGIcon icon={icon} />
  //       <CountUp end={value} separator="," suffix="+" className="countUp" />
  //       <strong>{title}</strong>
  //     </div>
  //   </div>
  // );

  return (
    <div id="counterSection">
      {counterData?.map((item: any) => {
        return (
          <div key={item.id} className={`counter ${item.className}`}>
            <div className="details">
              <SVGIcon icon={item.icon} />
              <CountUp
                end={item.count}
                separator=","
                suffix="+"
                className="countUp"
              />
              <strong>{item.title}</strong>
            </div>
          </div>
        );
      })}
      {/* {counter("primary oneItem", "globe", 600000, "Citizens")}
      {counter("secondary twoItem", "state", 5, "States")}
      {counter("secondary threeItem", "hand", 20, "Corporations")}
      {counter("primary fourItem", "office", 5000, "Government Officers")} */}
    </div>
  );
};
export default CounterSection;
