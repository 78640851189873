import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Certificate1 from "../../../../../assets/images/certificate/certificate1.png";
import Certificate2 from "../../../../../assets/images/certificate/certificate2.png";
import Certificate3 from "../../../../../assets/images/certificate/certificate3.png";

const CertificateSection: React.FC = () => {
  const setting = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    // autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <Slider {...setting} className="certificateList">
      <img className="slideItem" src={Certificate1} alt="Certificate1" />
      <img className="slideItem" src={Certificate2} alt="Certificate2" />
      <img className="slideItem" src={Certificate3} alt="Certificate3" />
      <img className="slideItem" src={Certificate1} alt="Certificate1" />
      <img className="slideItem" src={Certificate2} alt="Certificate2" />
      <img className="slideItem" src={Certificate3} alt="Certificate3" />
    </Slider>
  );
};
export default CertificateSection;
