import React from "react";
import { Col, Row } from "antd";

import { ReactComponent as BannerImg } from "../../../../assets/images/contactBanner.svg";

const Banner: React.FC<any> = () => {
  return (
    <div className="banner">
      <div className="container">
        <Row gutter={[60, 0]} align={"middle"}>
          <Col xs={24} lg={12}>
            <h4 className="title">Help and support</h4>
          </Col>
          <Col xs={24} lg={12}>
            <BannerImg />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Banner;
