import React from 'react'


const PricingTable = (props: any) => {
    const { planPrice } = props

    return (
        <table className='priceTable'>


            {/* <td>
                    {planPrice.details.map((item: any) => {
                        return (
                            <p>{ item?.title}</p>
                    )
                    })}
                </td> */}
            <tr >
                {planPrice.data.map((item: any,index:number) => {
                    
                    return (

                        <td key={index}>
                            <h2>{item.title}</h2>
                            <h3>{item?.price}</h3>

                            <p>{item.description}</p>
                            {item?.featuresOne.map((item: any) => item?.checklist.map((item: any, index: number) => {
                                return (
                                    <div key={index} className='innerContent'>
                                        <p>{item.title}</p>
                                        {item.checked === true ? "true" : "false"}
                                    </div>
                                )
                            }))}


                            {item?.featuresTwo.map((item: any) => item?.checklist.map((item: any, index: number) => {
                                return (
                                    <div key={index} className='innerContent'>
                                        <p>{item.title}</p>
                                        {item.checked === true ? "true" : "false"}
                                    </div>
                                )
                            }))}
                        </td>
                    )
                })}
            </tr>


        </table>

    )
}

export default PricingTable