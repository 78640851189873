import React from "react";
import { Link } from "react-router-dom";

const InstantSupport: React.FC<any> = ({ supportData }) => {
  const { section, list } = supportData;

  const iconList = (list: any, key: number) => (
    <div className="list" key={key}>
      <strong>{list.name}</strong>
      <Link to={`tel:${list.detail}`} title={list.detail}>{list.detail}</Link>
    </div>
  );

  return (
    <div className="supportDetails">
      <h4 className="section">{section}</h4>

      <div className="box">
        {list?.map((item: any, index: number) => iconList(item, index))}
      </div>
    </div>
  );
};

export default InstantSupport;
