import React, { useState } from "react";
import FieldInsInner from "./GpsRoadInner.json";
import "../../../assets/styles/modules/FieldInsPectionModuleInner.less";
import { Button, Col, Form, Row } from "antd";
import squreDots from "../../../assets/images/squreDots.png";
import { ArrowUpOutlined } from "@ant-design/icons";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { AppDispatch } from "../../../store/app";
import { doContactSubmit } from "../Contact/utils/ContactSlice";
import { Notification } from "../../../config/Global";
import { assignErrorToInput } from "../../../store/api";
import { useDispatch } from "react-redux";
import { ContactRequest } from "../Contact/Validation";
import sendBtn from "../../../assets/images/demoSend.png";
import demoLine from "../../../assets/images/demoLine.png";
import { ReactComponent as RequiredTwo } from "../../../assets/images/require2.svg";
import { ReactComponent as CitizensDots } from "../../../assets/images/CitizensDots.svg";
import DemoModal from "../../layouts/MainLayout/elements/DemoModal";
import banner from "../../../assets/images/banner.png";
import roadChekingModuleIcon1 from "../../../assets/images/road-cheking-module-Icon1.png";
import roadChekingModuleIcon2 from "../../../assets/images/road-cheking-module-Icon2.png";
import roadChekingModuleIcon3 from "../../../assets/images/road-cheking-module-Icon3.png";
import roadChekingModuleIcon4 from "../../../assets/images/road-cheking-module-Icon4.png";
import roadChekingModuleIcon5 from "../../../assets/images/road-cheking-module-Icon5.png";
import roadChekingModuleIcon6 from "../../../assets/images/road-cheking-module-Icon6.png";
import roadChekingModuleIcon7 from "../../../assets/images/road-cheking-module-Icon7.png";
import roadChekingModuleIcon8 from "../../../assets/images/road-cheking-module-Icon8.png";
import roadChekingModuleIcon9 from "../../../assets/images/road-cheking-module-Icon9.png";
import roadChekingModuleIcon10 from "../../../assets/images/road-cheking-module-Icon10.png";
import roadChekingModuleIcon11 from "../../../assets/images/road-cheking-module-Icon11.png";
import roadChekingModuleIcon12 from "../../../assets/images/road-cheking-module-Icon12.png";
import roadChekingModuleIcon13 from "../../../assets/images/road-cheking-module-Icon13.png";
import roadChekingModuleIcon14 from "../../../assets/images/road-cheking-module-Icon14.png";
import roadChekingModuleIcon15 from "../../../assets/images/road-cheking-module-Icon15.png";
import roadChekingModuleIcon16 from "../../../assets/images/road-cheking-module-Icon16.png";
import roadChekingModuleIcon17 from "../../../assets/images/road-cheking-module-Icon17.png";
import roadChekingModuleIcon18 from "../../../assets/images/road-cheking-module-Icon18.png";
import cityBg from "../../../assets/images/city_bg1.png";
import blogDots from "../../../assets/images/BlogDetailsDot.png";

const GpsRoadInner = () => {
  const projectIcon = [
    roadChekingModuleIcon1,
    roadChekingModuleIcon2,
    roadChekingModuleIcon3,
    roadChekingModuleIcon4,
    roadChekingModuleIcon5,
    roadChekingModuleIcon6,
    roadChekingModuleIcon7,
    roadChekingModuleIcon8,
    roadChekingModuleIcon9,
    roadChekingModuleIcon10,
    roadChekingModuleIcon11,
    roadChekingModuleIcon12,
    roadChekingModuleIcon13,
    roadChekingModuleIcon14,
    roadChekingModuleIcon15,
    roadChekingModuleIcon16,
    roadChekingModuleIcon17,
    roadChekingModuleIcon18,
  ];
  const demo = FieldInsInner?.demo;
  const [form] = Form.useForm();
  const [openDemoModal, setOpenDemoModal] = useState<boolean>(false);
  const handleDemoModal = () => {
    setOpenDemoModal(!openDemoModal);
  };
  const dispatch: AppDispatch = useDispatch();
  const handleSubmit = (data: any) => {
    const payload = `full_name=${data.full_name}&email=${data.email}&subject=${data.subject}&message=${data.message}`;
    dispatch(doContactSubmit(payload))
      .then((res: any) => {
        res?.data?.NOTIFICATION.map((err: string) =>
          Notification.success({
            message: err,
          })
        );
        form.resetFields();
      })
      .catch((error: any) => {
        assignErrorToInput(form, error?.NOTIFICATION);
        error?.response?.data?.NOTIFICATION.map((err: string) =>
          Notification.error({
            message: err,
          })
        );
        // form.resetFields();
      });
  };
  return (
    <div id="FieldInsPectionModuleInner">
      <section className="banner" style={{ backgroundImage: `URL(${banner})` }}>
        <h1 className="title">
          {FieldInsInner.fieldInsPectionModuleTitle.title}
        </h1>
      </section>
      <section className="middelSection">
         <div className="dotsImg"><img src={blogDots} alt="blogDots" /></div>
        <div className="middelSectionInnerMain">
          {FieldInsInner.fieldInsInnerBoxSec.map((item: any, index: number) => {
            return (
              <section className="middelSectionInner" key={index}>
                <div className="container">
                  <div className="projectList">
                    <div className="projectListInner">
                      <h2 className="title">{item.title}</h2>
                      <p className="description">{item.description}</p>
                    </div>
                    <div className="projectImg">
                      <div className="projectImgInner projectImgInnerIconBg">
                        <img src={projectIcon[index]} alt="projectIcon" />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            );
          })}
        </div>
        <div className="citybg">
          <img src={cityBg} alt="cityBg" />
        </div>
      </section>

      <section className="demoForm">
        <div className="reportSection">
          <div className="featuresOfFieldLines">
            <img src={demoLine} alt="demoLine" />
          </div>
          <div className="miniContainer">
            <Row gutter={[30, 30]}>
              <Col xs={24} lg={14}>
                {demo?.management.map((item: any, index: number) => {
                  return (
                    <div key={index} className="crmServiceContent">
                      <h2 className="crmServiceTitle">{item.title}</h2>
                      <ul className="crmServiceList">
                        {item?.list.length > 0 &&
                          item?.list.map((item: any) => {
                            return (
                              <li>
                                <ArrowUpOutlined />
                                {item.content}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  );
                })}
              </Col>
              <Col xs={24} lg={10}>
                <div className="contactForm">
                  <FormBox form={form} onFinish={handleSubmit}>
                    <h2 className="crmServiceTitle">Request a Demo</h2>
                    <div className="nameInput">
                      <InputBox.Text
                        required
                        label="Your Name *"
                        name="full_name"
                        rules={ContactRequest().full_name("Your Name")}
                        placeholder="Enter your name"
                      />

                      <InputBox.Text
                        required
                        label="Mobile No *"
                        name="Mobile No"
                        // rules={ContactRequest().email("Email")}
                        placeholder="Enter your mobile no"
                      />
                    </div>
                    <InputBox.Text
                      required
                      label="Your Email *"
                      name="email"
                      rules={ContactRequest().email("Email")}
                      placeholder="Enter your email id"
                    />
                    <InputBox.TextArea
                      required
                      label="Write Comment *"
                      name="message"
                      rules={ContactRequest().message("Your Massage")}
                      placeholder="Enter your Comment"
                      rows={1}
                    />
                    <Button htmlType="submit">
                      Submit
                      <img src={sendBtn} alt="send" />
                    </Button>
                  </FormBox>
                </div>
              </Col>
            </Row>
            <div className="forCitizens">
              <div className="requiredTwo">
                <RequiredTwo />
              </div>
              <div className="CitizensDots">
                <CitizensDots />
              </div>
              <span className="subTitle">
                {FieldInsInner.solutionCitizens.subtitle}
              </span>
              <h2>{FieldInsInner.solutionCitizens.title}</h2>
              <Button
                onClick={() => {
                  handleDemoModal();
                }}
                title="View Details"
                className="ant-btn ant-btn-default buttonLink primaryColor outline demoBtn"
              >
                <span>{FieldInsInner.solutionCitizens.button}</span>
              </Button>

              <div className="CitizensDotsBottom">
                <CitizensDots />
              </div>

              <div className="requiredRightCircle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="242"
                  height="241"
                  viewBox="0 0 242 241"
                  fill="none"
                >
                  <g opacity="0.31">
                    <circle
                      cx="121.055"
                      cy="120.5"
                      r="120.5"
                      fill="url(#paint0_linear_829_3894)"
                    />
                    <circle
                      cx="121.055"
                      cy="120.5"
                      r="120.5"
                      fill="url(#paint1_linear_829_3894)"
                    />
                    <circle
                      cx="121.471"
                      cy="124.892"
                      r="77.0294"
                      transform="rotate(106.834 121.471 124.892)"
                      fill="url(#paint2_linear_829_3894)"
                    />
                    <circle
                      cx="121.471"
                      cy="124.892"
                      r="77.0294"
                      transform="rotate(106.834 121.471 124.892)"
                      fill="url(#paint3_linear_829_3894)"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_829_3894"
                      x1="121.055"
                      y1="0"
                      x2="121.055"
                      y2="241"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="white" />
                      <stop offset="1" stop-color="white" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_829_3894"
                      x1="121.055"
                      y1="0"
                      x2="121.055"
                      y2="241"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="white" />
                      <stop offset="1" stop-color="white" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_829_3894"
                      x1="121.471"
                      y1="47.8628"
                      x2="121.471"
                      y2="201.922"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="white" />
                      <stop offset="1" stop-color="white" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient
                      id="paint3_linear_829_3894"
                      x1="121.471"
                      y1="47.8628"
                      x2="121.471"
                      y2="201.922"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="white" />
                      <stop offset="1" stop-color="white" stop-opacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="squreDotsRight">
          <img src={squreDots} alt="RequiredLeftCircle" />
        </div>
      </section>
      <DemoModal
        openDemoModal={openDemoModal}
        closeDemoModal={handleDemoModal}
      />
    </div>
  );
};

export default GpsRoadInner;