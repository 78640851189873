import React from "react";
import Slider from "react-slick";
import "../../assets/styles/commonSections/work.less";
import Slider1 from "../../assets/images/slider/1695362050739.jpeg";
import Slider2 from "../../assets/images/slider/Agartala-Splash-Screen--1-New.jpg";
import Slider3 from "../../assets/images/slider/Faridabad_2.png";
import Slider4 from "../../assets/images/slider/ghaziyzbad_2.png";
import Slider5 from "../../assets/images/slider/Porbander_2.png";
import Slider6 from "../../assets/images/slider/qutibminar_2.jpg";
import Slider7 from "../../assets/images/slider/smartCity_2.png";
import Slider8 from "../../assets/images/slider/splash_4.png";
import Slider9 from "../../assets/images/slider/V3_Splash.png";
import Slider10 from "../../assets/images/slider/V4_Splash.png";

import Slider11 from "../../assets/images/slider/aligarh.png";
import Slider12 from "../../assets/images/slider/AMC.png";
import Slider13 from "../../assets/images/slider/DDA_screen.png";
import Slider14 from "../../assets/images/slider/MCD.png";
import Slider15 from "../../assets/images/slider/UMC-seva.png";

const Work: React.FC = () => {
  const settings = {
    arrows: false,
    onfocus: true,
    autoplay: true,
    dots: false,
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,

    initialSlide: 3,
    swipeToSlide: true,
  };
  return (
    <section id="Work">
      <h3 className="title">COMPREHENSIVE AND INTEGRATED CIVIC SOLUTION</h3>
      <div className="slider-bg">
        <div className="sliderContainer">
          <Slider {...settings}>
            <img src={Slider1} alt="1" />
            <img src={Slider2} alt="2" />
            <img src={Slider3} alt="3" />
            <img src={Slider4} alt="4" />
            <img src={Slider5} alt="5" />
            <img src={Slider6} alt="6" />
            <img src={Slider7} alt="7" />
            <img src={Slider8} alt="8" />
            <img src={Slider9} alt="9" />
            <img src={Slider10} alt="10" />
            <img src={Slider11} alt="11" />
            <img src={Slider12} alt="12" />
            <img src={Slider13} alt="13" />
            <img src={Slider14} alt="14" />
            <img src={Slider15} alt="15" />
          </Slider>
        </div>
      </div>
      <div className="circles">
        <div className="circle1"></div>
        <div className="circle2"></div>
        <div className="circle3"></div>
      </div>
    </section>
  );
};
export default Work;
