import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Card, Col, Row } from "antd";
import SVGIcon from "../../utils/SVGIcon";
import MediaImg from "../../assets/images/media1.png";
import "../../assets/styles/commonSections/mediaCoverage.less";
import moment from "moment";
import { Link } from "react-router-dom";
import { AppDispatch } from "../../store/app";
import { useDispatch } from "react-redux";
import { getBlogData } from "../../views/modules/Blog/Utils/BlogSlice";

const MediaCoverage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()
  const [blogData, setBlogData] = useState<any>([])



  useEffect(() => {
    dispatch(getBlogData({ page: 1, per_page: 9 })).then((data: any) => {
      setBlogData(data.blogs)
    }).catch((e: any) => e)

  }, [dispatch])
  const firstSixData = blogData.slice(0, 6)


  const settings = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const { Meta } = Card;

  const slideItem = (
    postImg: string,
    date: string,
    title: string,
    description: string,
    slug: string,
    img: string,
    index: number
  ) => (
    <Card
      key={index}
      className="slideItem"
      hoverable
      cover={
        <>
          <span className="date">{moment(date).format("MMM, DD YYYY")}</span>
          <img src={img} alt={title} />
        </>
      }
    >
      <Meta title={<Link to={`/blog/${slug}`} rel="noreferrer" title={title} >{title}</Link>} description={description} />
      <Link to={`/blog/${slug}`} rel="noreferrer" title="Read More" className="link">
        Read More <SVGIcon icon="rightTopArrow" />
      </Link>
    </Card>
  );

  return blogData.length > 0 ? (
    <section id="MediaCoverage">
      <Row gutter={20} align={"middle"}>
        <Col xs={24} lg={{ span: 5, offset: 1 }}>
          <h2 className="titleTag leftLine primaryColor">
            LATEST NEWS
          </h2>
          <p>
            Civic Solutions Pvt. Ltd is a division of Silicon IT Hub Pvt. Ltd.,
            one of the fastest growing web and mobile development companies.
          </p>
          <Link
            to={"/blog"}
            title="View More"
            className="buttonLink primaryColor"
          >
            View More <SVGIcon icon="rightTopArrow" />
          </Link>
        </Col>
        <Col xs={24} lg={18}>
          <div className="sliderContainer">
            <Slider {...settings}>
              {firstSixData.length > 0
                ? firstSixData.map((item: any, index: number) => {
                  return slideItem(
                    MediaImg,
                    item.publish_date,
                    item.title,
                    item.short_description,
                    item.slug,
                    item.image_url,
                    index
                  );
                })
                : null}
            </Slider>
          </div>
        </Col>
      </Row>
    </section>
  ) : null;
};
export default MediaCoverage;
