import React from "react";
import Slider from "react-slick";
import SVGIcon from "../../utils/SVGIcon";
import "../../assets/styles/commonSections/systemModule.less";
import { Link } from "react-router-dom";

const SystemModule: React.FC = () => {
    
  const setting = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    // autoplay: true,
    speed: 1000,
    // cssEase: "linear",
    pauseOnHover: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const listItem = (
    listTitle: string,
    listDetails: string,
    link: string,
    icon: string
  ) => (
    <div className="moduleItem">
      <div className="icon">
        <SVGIcon icon={icon} />
      </div>
      <h3 className="listTitle">{listTitle}</h3>
      <p className="listDetails">{listDetails}</p>
      
      <Link to={link} title="Read More" rel="noreferrer" className="link">
        Read More <SVGIcon icon="rightTopArrow" />
      </Link>
      
    </div>
  );

  return (
    <section id="SystemModule">
      <div className="container">
        <strong className="titleTag leftRightLine primaryColor">
        Your Citizen Non-Emergency Service Application
        </strong>
        <h2 className="titleSection">Key Modules for Smart City Services</h2>
        <Slider {...setting} className="moduleList">
          {listItem(
            "Field Inspection Module",
            "The Field Work Monitoring Module makes it easy for field officers to report their daily inspection tasks across various categories. Field officers and inspectors can capture a photo of the field that gets the latitude and longitude information along with the ward and zone.",
            "/field-work-monitoring-module",
            "search"
          )}
          {listItem(
            "GPS Attendance Module",
            "It is useful in making the attendance tracking process easy and effective by pinpointing the officer’s accurate location using GPS. It also enables employees or officers to mark their presence onsite or from remote job sites.",
            "/gps-attendance-module",
            "map"
          )}
          {listItem(
            "GPS Road Checking Module",
            "It has replaced the traditional method of reporting material testing, road construction, inspection, and geotechnical investigations. As an easy-to-use module, the GPS Road Checking Module can also eliminate the paperwork effectively.",
            "/gps-road-checking-module",
            "location"
          )}
          {listItem(
            "Project Tracking Module",
            "This module is the most advanced tool of the SmartCity-311 mobile suite. It enables officers to generate project reports using their smartphones. They can create a report by filling in complete information like a photo, location, etc. about the project.",
            "/project-tracking-module",
            "monitorSearch"
          )}
        </Slider>
      </div>
    </section>
  );
};
export default SystemModule;
