import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./config/RouterConfig";
import { handleStorageEvents } from "./config/Global";
import "animate.css/animate.min.css";
import { HelmetProvider } from "react-helmet-async";
import HelmetComponent from "./components/Seo/HelmetComponent";

const App: React.FC = () => {
  useEffect(() => {
    window.addEventListener("storage", handleStorageEvents);
  }, []);
  return (
    <BrowserRouter>
      <HelmetProvider>
        <Router />
      <HelmetComponent />
      </HelmetProvider>
    </BrowserRouter>
  );
};

export default App;
