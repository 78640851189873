import React, { useEffect } from "react";
import SVGIcon from "../../../../utils/SVGIcon";
import { Button } from "antd";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";

interface MyComponent {
  navigation: any;
  hiringLink: any;
  demoLink: any;
  closeDemoModal: () => void;
  handleMenuOpen: () => void;
}

const MenuStrip: React.FC<MyComponent> = ({
  navigation,
  hiringLink,
  demoLink,
  closeDemoModal,
  handleMenuOpen,
}) => {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location]);

  return (
    <div className="menuStrip">
      <div className="nav">
        {navigation?.map((item: any, index: number) => {
          return (
            <NavLink
              key={index}
              to={item.link}
              title={item.name}
              onClick={() => handleMenuOpen()}
            >
              {item.name}
            </NavLink>
          );
        })}
      </div>

      <div className="actionBtns">
        {hiringLink && (
          <NavLink to={hiringLink.link}
            onClick={() => handleMenuOpen()}
            title={hiringLink.name}
          >
            {/* target="_blank" rel="noreferrer" */}
            {hiringLink.name} <SVGIcon icon="rightTopArrow" />
          </NavLink>
        )}
        {demoLink && (
          <Button
            // href={demoLink.link}
            className="buttonPrimary"
            onClick={() => {
              closeDemoModal();
              handleMenuOpen();
            }}
            title={demoLink.name}
          >
            {demoLink.name} <SVGIcon icon="rightTopArrow" />
          </Button>
        )}
      </div>
    </div>
  );
};
export default MenuStrip;

// <a
//             href={demoLink.link}
//             target="_blank"
//             rel="noreferrer"
//             className="buttonPrimary"
//           >
//             asdfs
//             {demoLink.name} <SVGIcon icon="rightTopArrow" />
//           </a>
