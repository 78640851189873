export const CONSTANT = {
  DATE_FORMAT: "DD-MM-YYYY",
  TIME_FORMAT: "HH:mm",
  DATE_TIME_FORMAT: "DD-MM-YYYY HH:mm",
  POST_DATE_FORMAT: "YYYY-MM-DD",
  POST_TIME_FORMAT: "HH:mm",
  POST_DATE_TIME_FORMAT: "YYYY-MM-DD HH:mm",

  PER_PAGE_RECORD: [
    { id: "50", value: "50 Per Page" },
    { id: "100", value: "100 Per Page" },
    { id: "500", value: "500 Per Page" },
    { id: "1000", value: "1000 Per Page" },
  ],
  PAGE_LINK: {
    HOME: "/",
  },

  OTHER_LINK: {
    EMAIL_INFO: "info@everythingcivic.com",
    TEL_904: "+91 99797 70904",
    FACEBOOK: "https://www.facebook.com/",
    TWITTER: "https://twitter.com/",
    LINKEDIN: "https://www.linkedin.com/",
  },
};
