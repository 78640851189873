import React from "react";
import Banner from "./components/Banner";
import HomeJson from "./home.json";

import "../../../assets/styles/modules/home.less";
import LogoSlider from "./components/LogoSlider";
import About from "./components/About";
import Solution from "../../../components/Sections/Solution";
import Work from "../../../components/Sections/Work";
import SystemModule from "../../../components/Sections/SystemModule";
import MediaCoverage from "../../../components/Sections/MediaCoverage";
import Testimonial from "../../../components/Sections/Testimonial";
import TestimonialTwo from "../../../components/Sections/TestimonialTwo";
import OurCoreFeatures from "../../../components/Sections/OurCoreFeatures";
import TopBenefits from "../../../components/Sections/TopBenefits";

const Home: React.FC = () => {
  const { banner, about, counter } = HomeJson;

  // useEffect(() => {
  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  // }, []);

  return (
    <>
      <Banner bannerData={banner} />
      <LogoSlider />
      <About aboutData={about} counterData={counter} />
      <Solution />
      <SystemModule />
      <Work />
      <MediaCoverage />
      <Testimonial />
      <OurCoreFeatures />
      <TopBenefits />
      <TestimonialTwo />
    </>
  );
};

export default Home;
